import { createPortal } from "react-dom";
import { useState, useEffect } from "react";
import Compte from "../pages/Compte";
import { useOutsideClick } from "../../utils/hooks";

const ModalAuth = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
    document.body.classList.remove("overflow-y-hidden");
    if (user) {
      localStorage.setItem("close", true);
    }
  };

  useEffect(() => {
    if (showModal && document.querySelector("#authentication-modal")) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }, [showModal]);

  const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !event.target.closest(".toast")
      && 
      !event.target.closest(".modal-twoSteps")
      &&
      !event.target.closest(".country-list")
    ) {
      closeModal();
    }
  };

  const ref = useOutsideClick(handleClickOutside);

  if (!user) {
    return null;
  } else if (user) {
    const closeAuth = JSON.parse(localStorage.getItem("close"));

    if (user.isAuth || closeAuth === true) {
      return null;
    }
  }

  if (!showModal) {
    return null;
  }

  return createPortal(
    <div
      id="authentication-modal"
      aria-hidden="true"
      className="fixed h-full bg-blackRgba top-0 left-0 right-0 z-50  w-full p-2 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
    >
      <div className="relative modalResHeight container-modal left-1/2 top-1/2 rounded-lg shadow bg-white w-11/12 lg:w-1/2 ">
        <button
          onClick={closeModal}
          type="button"
          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          data-modal-hide="authentication-modal"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close modal</span>
        </button>

        <div ref={ref} className=" sm:px-4 py-4 lg:p-0">
          <Compte
            close={() => {
              closeModal();
            }}
          />
        </div>
      </div>
    </div>,
    document.getElementById("root")
  );
};

export default ModalAuth;
