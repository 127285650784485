import CapsuleMoreDetails from "./CapsuleMoreDetails";
import StepsBooking from "./StepsBooking";
import {
  H24Icon,
  InsonoriseIcon,
  PriseIcon,
  TvIcon,
  WifiIcon,
} from "../../data/Icons";
import { useEffect, useState } from "react";
import logoLyon from "../../assets/images/logo-lyon-aeroport.svg";
import imageCapsuleDesktop from "../../assets/images/capsule-image-desktop@2x.jpg";
import imageCapsuleMobile from "../../assets/images/capsule-image-mobile.png";
import { useTranslation } from "react-i18next";

const CapsuleDetails = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="width-padding mt-10 mb-16">
      {/* Title */}
      <div className="capsule-title flex justify-between items-center">
        <div className="item-title">
          <h2 className="text-darkBlue font-bold text-xs sm:text-2xl">
            {t("capsulePage.place")}
          </h2>
          <span className="text-dimGreey text-xs sm:text-base	 font-medium">
            {t("capsulePage.exactePlace")}
          </span>
        </div>
        <img
          className="sm:mr-6"
          src={logoLyon}
          alt="aeroport lyon"
          width={width <= 640 ? 70 : 100}
          height="48"
        />
      </div>

      {/*  image Capsule + infos */}
      <div className="capsule-image mt-4 -mx-5 sm:mx-0 relative ll:mb-24">
        <img
          src={width <= 640 ? imageCapsuleMobile : imageCapsuleDesktop}
          width="100%"
          className="object-cover"
          alt="imageCapsule"
        />
        <div
          className="infos-capsule absolute top-0 left-0 bg-white bottom-0 opacity-79 ll:bg-inherit
	 ll:top-auto ll:-bottom-14  "
        >
          <div className="items-info flex justify-around flex-col items-center h-full	px-3 ll:px-0 ll:flex-row ll:gap-x-6   ll:justify-between">
            <div className="item-info">
              <WifiIcon />
              <h3 className="text-darkBlue px-4">{t("capsulePage.wifi")}</h3>
            </div>
            <div className="item-info">
              <TvIcon />
              <h3 className="text-darkBlue px-4">{t("capsulePage.tv")}</h3>
            </div>
            <div className="item-info">
              <PriseIcon />
              <h3 className="text-darkBlue px-4">{t("capsulePage.prise")}</h3>
            </div>
            <div className="item-info">
              <H24Icon />
              <h3 className="text-darkBlue px-4">
                {t("capsulePage.disponible")}
              </h3>
            </div>
            <div className="item-info">
              <InsonoriseIcon />
              <h3 className="text-darkBlue px-4">
                {t("capsulePage.insonorise")}
              </h3>
            </div>
          </div>
        </div>
      </div>

      {/* Capsule présentation:  */}

      <div className="mt-10 lg:flex lg:justify-between ">
        <div className="moreInfos lg:w-2/4">
          <h3 className="text-darkBlue font-semibold text-base mb-5	sm:text-xl	">
            {t("capsulePage.heading")}
          </h3>
          <p className="text-darkGreey text-sm	sm:text-base  sm:max-w-none">
            {t("capsulePage.description")}
          </p>
          {screenWidth <= 1024 && (
            <div className=" mt-4 block md:max-w-80  lg:hidden mobile-steps">
              <StepsBooking />
            </div>
          )}
          <div className="more-details">
            <CapsuleMoreDetails />
          </div>
        </div>
        {screenWidth >= 1024 && (
          <div className=" hidden  lg:block ll:w-2/5 lg:w-45">
            <StepsBooking />
          </div>
        )}
      </div>
    </div>
  );
};

export default CapsuleDetails;
