import { Link, useNavigate } from "react-router-dom";
import Button from "../ui/Button";
import { useState } from "react";
import Modal from "../ui/Modal";
import Toast from "../ui/Toast";
import PasswordField from "../ui/PasswordField";
import Loader from "../ui/Loader";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import jwt_decode from 'jwt-decode';
import { useTranslation } from "react-i18next";
const publicUrl = process.env.PUBLIC_URL;
const imgUrl = publicUrl + "/logo@2x.png";


const AdminLogin = () => {
  const {t}= useTranslation();
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [otd, setOtd] = useState("");
  const [openModal, setIsModalOpen] = useState(false);
  const [steps, setSteps] = useState(1);
  const [loaderLogin, setLoaderLogin] = useState(false)
  const [loaderModal, setLoaderModal] = useState(false); //loader just in modal

  const axiosPrivate = useAxiosPrivate()
  const naviagate = useNavigate()

  // Modal config open/close
  const handleOpenModal = () => {
    setIsModalOpen(true);
    setSteps(1)
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  //Toast props  // responseType => "success" or "error"

  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [responseType, setResponseType] = useState("success");
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };

  // Submit form login

  const submit = async (e) => {
    // variables email, password
    e.preventDefault();
    if (password === "" || email === "") {
      const emailNotEmpty = email === "";
      const passwordNotEmpty = password === "";
      const messageErrors = ` Veuillez entrer valide:
          ${emailNotEmpty ? "Email" : ""}${emailNotEmpty && passwordNotEmpty ? "," : ""
        }
          ${passwordNotEmpty ? "Password" : ""}
          `;
      displayToast(messageErrors, "error");
    } else {
      try {
        const res = await axiosPrivate.post('admin/auth/login', {
          email: email,
          password: password
        })

        if (res) {
          

          const decodedData = jwt_decode(res.data.accessToken)
          let newData = { ...res.data, userType: decodedData['userType'], userId: decodedData['userId'] }
          newData = JSON.stringify(newData)
          localStorage.setItem('user', newData)
          displayToast('You are logged in successfully', 'success')
          naviagate("/ecapsule-admin/dashboard", { replace: true })
          

        }
      } catch (error) {
        if (error.response.status !== 401) {
          displayToast(t(error.response.data.message), 'error')
        } else {
          displayToast(t(error.message), 'error')
        }
      }
      /// displayToast(message,"success")
    }
  };

  // step 1 Confirm email

  const stepConfirmEmail = async () => {

    // variables: ==> confirmEmail

    // setSteps(2);
    if (confirmEmail !== "") {
      try {
        const res = await axiosPrivate.get(`admin/auth/sendResetPasswordEmail/${confirmEmail}`)
        if (res.data.success) {
          displayToast(`Merci de verifier votre boite mail!`, "success")
          setSteps(2);
        } else {
          displayToast(res.data.message)
        }
      } catch (error) {
        if (error.response.status !== 401) {
          displayToast(t(error.response.data.message))
        } else {
          displayToast(t(error.message))
        }
      }

    }
    else {
      /// displayToast(message,"error")
    }
  };

  // step 2 confirm otd

  // const stepConfirmOtd = () => {
  //   // variables: ==> otd
  //   if (otd) {
  //     // try {
  //     //   const res = axiosPrivate.patch('admin/auth/resetPassword',
  //     //     {
  //     //       email: confirmEmail,
  //     //       otd: otd,

  //     //     })
  //     //   /// displayToast(message,"success")
  //     setSteps(3)
  //     // } catch (error) {

  //     // }
  //   }
  //   else {
  //     /// displayToast(message,"error")
  //   }
  // };

  // Step 3 Mot de passe modification

  //variables ==>  // newPassword confirmNewPassword
  const stepModifyPassword = async (e) => {
    e.preventDefault();
    if (newPassword === confirmNewPassword && (newPassword !== "" && confirmNewPassword !== "") && otd) {
      try {
        const res = await axiosPrivate.patch('admin/auth/resetPassword',
          {
            email: confirmEmail,
            otd: otd,
            password: newPassword
          })
        if (res.data.success) {
          displayToast(`Password updated successfully!`, "success")
          handleCloseModal()
        } else {
          displayToast(t(res.data.message), 'error')
        }
      } catch (error) {
        if (error.response.status !== 401) {
          displayToast(t(error.response.data.message), 'error')
        } else {

        }
      }
      /// displayToast(message,"success")
      // handleCloseModal()
    }
    else {
      displayToast("Vérifier les champs de mot de passe", "error")
    }

  }

  return (
    <div className="bg-[#f0f0f1] h-screen w-screen flex flex-col items-center justify-center">
      {/* Form Infos */}
      <form
        onSubmit={submit}
        className="bg-white login-shadow py-7 px-5 mx-2 my-4  xs:w-350"
      >
        <Link
          className="bg-darkBlue mb-5 mx-auto max-w-200  rounded block px-6 py-4"
          to="/"
        >
          {" "}
          <img
            className="mx-auto"
            src={imgUrl}
            alt="logo"
            width="200"
            height="48"
          />
        </Link>
        <div>
          <label
            htmlFor="login-info"
            className="text-sm block text-darkBlue mb-2"
          >
            Adresse e-mail
          </label>
          <input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            id="login-info"
            autoComplete="email"
            className="border border-[#8c8f94] text-gray-900 text-sm rounded
 focus:border-darkGreen focus:outline-none block w-full p-2.5 mb-4"
            type="email"
          />
        </div>
        <div>
          <label
            htmlFor="login-password"
            className="text-sm block text-darkBlue mb-2"
          >
            Mot de passe
          </label>
          <PasswordField password={password} setPassword={setPassword} />
        </div>
        <Button
          btnContent={"Connexion"}
          customStyle={"btn-principal text-white text-center w-full"}
          type="submit"
        />
        {loaderLogin && (
          <div className="flex items-center justify-center mt-4 -mb-4">
            <Loader />
          </div>
        )}
      </form>
      <p
        onClick={handleOpenModal}
        className="text-center mt-7 text-sm underline text-darkBlue cursor-pointer"
      >
        Mot de passe oublié ?
      </p>
      <Modal
        isOpen={openModal}
        onClose={handleCloseModal}
        runLoader={loaderModal}
      >
        <div>
          {steps === 1 ? (
            <div>
              <p className="text-[#555d66]">
                Veuillez saisir votre adresse e-mail. Vous recevrez par e-mail
                un code pour créer un nouveau mot de passe.
              </p>
              <div>
                <label
                  htmlFor="userLogin"
                  className="text-sm block text-darkBlue mt-4 mb-2"
                >
                  E-mail
                </label>
                <input
                  value={confirmEmail}
                  autoComplete="email"
                  onChange={(e) => {
                    setConfirmEmail(e.target.value);
                  }}
                  id="userLogin"
                  className="border border-[#8c8f94] text-gray-900 text-sm rounded
 focus:border-darkGreen focus:outline-none block w-full p-2.5 mb-4"
                  type="email"
                />
              </div>
              <Button
                btnContent={"Confirmer email"}
                onClick={stepConfirmEmail}
                customStyle={
                  "btn-principal btn-sendMail text-white text-center w-full"
                }
                type={"button"}
              />
            </div>
          )
            //           : steps === 2 ? (
            //             <div>
            //               <p className="text-[#555d66]">
            //                 Veuillez saisir le code de vérification que vous avez récu par
            //                 email
            //               </p>
            //               <label
            //                 htmlFor="userLogin"
            //                 className="text-sm block text-darkBlue mt-4 mb-2"
            //               >
            //                 Vérification code
            //               </label>
            //               <input
            //                 value={otd}
            //                 onChange={(e) => {
            //                   setOtd(e.target.value);
            //                 }}
            //                 id="userLogin"
            //                 className="border border-[#8c8f94] text-gray-900 text-sm rounded
            //  focus:border-darkGreen focus:outline-none block w-full p-2.5 mb-4"
            //                 type="text"
            //               />
            //               <Button
            //                 btnContent={"Confirmer vérification code"}
            //                 onClick={stepConfirmOtd}
            //                 customStyle={
            //                   " bg-[#2271b1] hover:bg-[#135e96] btn-sendMail text-white text-center w-full"
            //                 }
            //                 type={"button"}
            //               />
            //             </div>
            //           ) 
            : (
              <form onSubmit={stepModifyPassword}>
                <p className="text-[#555d66]">
                  Veuillez saisir le code de vérification que vous avez récu par
                  email ainsi que votre nouveau <strong>mot de passe</strong>
                </p>
                <label
                  htmlFor="userLogin"
                  className="text-sm block text-darkBlue mt-4 mb-2"
                >
                  Vérification code
                </label>
                <input
                  value={otd}
                  onChange={(e) => {
                    setOtd(e.target.value);
                  }}
                  id="userLogin"
                  className="border border-[#8c8f94] text-gray-900 text-sm rounded
 focus:border-darkGreen focus:outline-none block w-full p-2.5 mb-4"
                  type="text"
                />
                <input
                  value={confirmEmail}
                  autoComplete="email"
                  onChange={(e) => {
                    setConfirmEmail(e.target.value);
                  }}
                  className="hidden"
                  type="email"
                  name="username"
                />
                <label
                  className="text-sm block text-darkBlue mt-4 mb-2"
                >
                  Nouveau mot de passe
                </label>
                <PasswordField password={newPassword} setPassword={setNewPassword} autoComplete="new-password" />
                <label
                  className="text-sm block text-darkBlue mt-4 mb-2"
                >
                  Confirmer votre Nouveau mot de passe
                </label>
                <PasswordField password={confirmNewPassword} setPassword={setConfirmNewPassword} autoComplete="new-password" />
                <Button
                  btnContent={"Modifier mot de passe"}
                  customStyle={
                    "btn-principal btn-sendMail text-white text-center w-full"
                  }
                  type={"submit"}
                />
              </form>
            )}
        </div>
      </Modal>
      {openToast && (
        <Toast
          responseType={responseType}
          message={message}
          open={openToast}
          close={handleCloseToast}
          duration={5000}
        />
      )}
    </div>
  );
};

export default AdminLogin;