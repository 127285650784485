
import { createPortal } from "react-dom";
import {useEffect } from "react";

const xmlns = "http://www.w3.org/2000/svg";
const svgError = 'M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
const svgSuccess = "M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"




const Toast = ({ responseType, message, close, }) => {



  useEffect(() => {

    //setIsVisible(open)
    
    const timer = setTimeout(() => {
      close()
     
    }, 7000);

    return () => {clearTimeout(timer)}
  }, [])




  // responseType => 'success' or 'error'
  // Message => DATA


  return (
    createPortal(<div className={`flex toast  items-center w-full max-w-xs p-4 mb-4  bg-white rounded-lg shadow text-darkBlue m-auto slide-in-top fixed -translate-x-1/2 left-1/2 top-10`}>
      <div className={`inline-flex  items-center justify-center flex-shrink-0 w-8 h-8 ${responseType === 'success' ? 'toastSuccess' : 'toastError'}`}>
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns={xmlns}
        >

          <path
            fillRule="evenodd"
            d={responseType === "success" ? svgSuccess : svgError}
            clipRule="evenodd"
          ></path>
        </svg>

        <span className="sr-only">Check icon</span>
      </div>
      <div className="ml-3 toast-content text-sm font-bold">{message} </div>
      <button

        onClick={() => close()}
        className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white"
        aria-label="Close"
      >
        <span className="sr-only">Close</span>
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns={xmlns}
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>

    </div>
      , document.getElementById('root')))
}




export default Toast;