



const BurgerMenu = ({openMenu, handleClose}) => {
 

  return (
    <div 
      className={`cursor-pointer flex flex-col justify-center items-center lg:hidden ${openMenu ? 'close' : ''}`}
      onClick={()=>{
        handleClose();
       
      }}
    >
      <span className="text-lightGreen uppercase text-xs mb-1" >{!openMenu? "Menu":"Close"}</span>
      <div className="burger-icon">
        <div className="w-9 mb-2 h-0.5 bg-lightGreen"></div>
        <div className="w-9 mb-2 h-0.5 bg-lightGreen"></div>
        <div className="w-9 h-0.5 bg-lightGreen"></div>
      </div>
    </div>
  );
};

export default BurgerMenu;