import { useEffect, useState } from "react";
import CapsuleDetails from "../widgets/CapsuleDetails";
import ModalAuth from "../widgets/ModalAuth";
import Toast from "../ui/Toast";
import { t } from "i18next";

// import { register } from "../../api/Auth";

const Home = () => {
  // const userData = register(mail, uuid)
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [responseType, setResponseType] = useState("success");
  const time = 3000;

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };

  useEffect(() => {
    const checkMessage = () => {
      const expiredStr = localStorage.getItem('compte.sessionExpired')
      if (expiredStr && expiredStr === 'expired') {
        displayToast(t("compte.sessionExpired"), 'error')
      }
      localStorage.removeItem('compte.sessionExpired')
    }
    // checkMessage()
  }, [])

  return (
    <main>
      <ModalAuth />
      <CapsuleDetails />
      {openToast && (
          <Toast
            responseType={responseType}
            message={message}
            open={openToast}
            close={handleCloseToast}
            duration={time}
          />
        )}
    </main>
  );
};

export default Home;
