import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

export const options = {
    responsive: true,
  maintainAspectRatio: false,
  scaleShowVerticalLines: false,
  plugins: {
    legend: {
      position: "top",
      align: "start",
      display: false,
    },
    title: {
      display: true,
      align: "start",
      text: "CA Annuel en K€",
      color: 'rgba(47, 59, 89, 1)',
      font: {
        size: 20,
      },
      padding: 20,
    },
  },
  scales: {
    y: {
      min: 0,
      ticks: {
        stepSize: 500,
        font: {
          size: 16,
          weight: "bold",
        },
        padding: 30,
        color: 'rgba(47, 59, 89, 1)',
      },
      grid: {
        color: "#FFFFFF",
        lineWidth: 2,
        drawTicks: false,
      },
    },
    x: {
      grid: {
        display: false,
        drawTicks: false,
        
      },
      ticks: {
        font: {
          size: 18,
          weight: "bold",
        },
        color: 'rgba(47, 59, 89, 1)',
      },
    },
  },
};

const labels = [
  "Jan",
  "Fev",
  "Mar",
  "Avr",
  "Mai",
  "Jui",
  "Juil",
  "Aou",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

let data = {
  labels,
  datasets: [
    {},
  ],
};

const CAChart = ({ dataset }) => {
  
  data = 
  // !onlyZerosPredicat
  //   ? {
  //       ...data,
  //       datasets: [
  //         {
  //           backgroundColor: ["white"],
  //           borderColor: ["white"],
  //           fill: false,
  //           borderWidth: 2,
  //           tension: 0.5,
  //           data: [
  //             "0",
  //             "500",
  //             "2000",
  //             "1500",
  //             "1000",
  //             "0",
  //             "500",
  //             "2000",
  //             "1500",
  //             "1000",
  //             "500",
  //             "2000",
  //           ],
  //         },
  //       ],
  //     }
  //   : 
    {
        ...data,
        datasets: [
          {
            label: "revenu (€)",
            backgroundColor: ["white"],
            borderColor: ["white"],
            fill: false,
            borderWidth: 2,
            tension: 0.5,
            data: dataset,
          },
        ],
      };


  return (
    // <div className="mt-6">
    <Line  options={options} data={data} />

    // </div>
  );
};

export default CAChart;
