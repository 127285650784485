import CAChart from "./CAChart";
import StatCard from "./ui/StatCard";
import MaterialReactTable, {
  MRT_ToolbarAlertBanner,
} from "material-react-table";
import { useEffect, useMemo, useState, useRef, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Box, Chip, Stack, Button, Typography, Divider } from "@mui/material";
import { DateTime } from "luxon";
import creative from "../../../assets/images/creative.svg";
import calendar2 from "../../../assets/images/calendar2.svg";
import money from "../../../assets/images/money.svg";
import numbers from "../../../assets/images/numbers.svg";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../../utils/utils";

const AdminDashboard = () => {
  const {t}= useTranslation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [capsuleNumber, setCapsuleNumber] = useState(0);
  const [canceledResa, setCanceledResa] = useState(0);
  const [caJour, setCAJour] = useState(0);
  const [resaJour, setResaJour] = useState(0);
  const [resaCompletee, setResaCompletee] = useState(0);
  const [caAnnuel, setCAAnnuel] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isChartData, setIsChartData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  //Toast props  // responseType => "success" or "error"
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [responseType, setResponseType] = useState("success");
  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };


  useEffect(() => {
    const table = document.querySelector(".MuiTable-root");
    if (table) {
      table.style = "";
    }

    const controller = new AbortController();
    const getAdminDashboard = async () => {
      try {
        const res = await axiosPrivate.get("admin/dash");
        if (res.data) {
          setCapsuleNumber(res.data.capsulesNumber);
          setCanceledResa(res.data.canceledBookings);
          setCAJour(res.data.dailyTO);
          setResaJour(res.data.dailyBookings);
          setResaCompletee(res.data.yearlyBookings);
          setCAAnnuel(res.data.yearlyTO);
          let newData = [];
          res.data.lastFiveReservations?.map((resa) => {
            let status = "";
            let facture = "N/A";
            switch (resa.status) {
              case 1:
                status = "À VENIR";
                break;
              case 2:
                status = "EN COURS";
                break;
              case 3:
                status = "PASSÉE";
                facture = resa._id;
                break;
              case 4:
                status = "ANNULÉE";
                break;
              default:
                break;
            }

            newData.push({
              client: resa?.name?.last
              ? `${capitalizeFirstLetter(`${resa?.name?.first}`)} ${capitalizeFirstLetter(`${resa?.name?.last}`)}`
              : "",
              lieu: resa.address ? resa.address : "Aéroport de Lyon",
              date: DateTime.fromISO(resa.startDate)
                .setLocale("fr")
                .toLocaleString(DateTime.DATE_SHORT),
              startTime: DateTime.fromISO(resa.startDate)
                .setLocale("fr")
                .toLocaleString(DateTime.TIME_SIMPLE),
              endTime: DateTime.fromISO(resa.endDate)
                .setLocale("fr")
                .toLocaleString(DateTime.TIME_SIMPLE),
              reservationId: resa.reservationNumber,
              status: status,
              facture: facture,
              user: {
                name: {
                  first: resa?.name?.first ? capitalizeFirstLetter(resa?.name?.first) : "",
                  last: resa?.name?.last ? capitalizeFirstLetter(resa?.name?.last) : "",
                },
                ...resa?.userId,
              },
            });
          });

          setChartData(res.data.chartData);

          setTableData([...newData]);
          setIsChartData(true);
        }
      } catch (error) {
        if (error.response.status !== 401) {
          // // console.log(error);
        }
      }
    };
    getAdminDashboard();

    return () => {
      setIsLoading(false);
      controller.abort();
    };
  }, []);

  // downloadInvoice

  const downloadInvoice = (id) => {
    const getInvoice = async (id) => {
      try {
        const res = await axiosPrivate.get(`/admin/dash/invoice/?id=` + id, {
          responseType: "blob",
        });
        if (res) {
          const file = new Blob([res.data]);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = "invoice.pdf";
          link.click();
        }
      } catch (error) {}
    };

    getInvoice(id).catch((error) => {
      displayToast(t(error.message), "error");
    });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "client", //access nested data with dot notation
        header: "Client",
        muiTableHeadCellProps: {
          align: "center",
        }
      },
      {
        accessorKey: "lieu",
        header: "Lieu",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "date", //normal accessorKey
        header: "Date",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "startTime",
        header: "Heure de début",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "endTime",
        header: "Heure de fin",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "reservationId",
        header: "N° résa",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        // muiTableHeadCellProps: {
        //   align: 'center',
        // },
        // muiTableBodyCellProps: {
        //   align: 'center',
        // },
        // size: 5,
        Cell: ({ cell, column }, value) => (
          <Stack direction="row" spacing={1}>
            <Chip
              label={cell.getValue()}
              sx={{
                backgroundColor:
                  cell.getValue() === "À VENIR"
                    ? "rgba(8, 168, 193, 1)"
                    : cell.getValue() === "EN COURS"
                    ? "rgba(55, 184, 60, 1)"
                    : cell.getValue() === "PASSÉE"
                    ? "rgba(193, 106, 8, 1)"
                    : "rgba(193, 8, 8, 1)",
                color: "rgba(255, 255, 255, 1)",
                fontWeight: "bold",
                fontSize: "12px",
                width: "90px",
                height: "20px",
                textAlign: "center",
                borderRadius: "6px",
                opacity: 1,
              }}
            />
          </Stack>
        ),
      },
      {
        accessorKey: "facture",
        header: "Facture",
        // muiTableHeadCellProps: {
        //   align: 'center',
        // },
        // muiTableBodyCellProps: {
        //   align: 'center',
        // },
        Cell: ({ cell, column }, value) =>
          cell.getValue() === "N/A" ? (
            <p>N/A</p>
          ) : (
            <p
              className="text-[#2F3B59] font-bold hover:underline hover:cursor-pointer"
              onClick={() => downloadInvoice(cell.getValue())}
            >
              Facture
            </p>
          ),
      },
    ],
    []
  );

  const tableInstanceRef = useRef(null);


  return (
    <div className="flex flex-col items-center lsm:items-start lg:mb-8 xl:mt-14 mx-[3%] max-w-95">
      <h2 className="text-darkBlue text-2xl xl:text-3xl font-semibold mb-4">
        Tableau de board
      </h2>
      <div className="flex justify-between w-full flex-wrap items-center my-5">
        <StatCard
          customStyle={"my-5"}
          title={capsuleNumber}
          description={"eCAPSULE"}
          icon={numbers}
        />
        <StatCard
          title={`${caJour.toFixed(2)} €`}
          description={"CA Jour"}
          icon={money}
        />
        <StatCard
          customStyle={"my-5"}
          title={resaJour}
          description={"Résa aujourd'hui"}
          icon={calendar2}
        />
      </div>
      {isChartData === true ? (
        <div
          className="mt-8 p-2  bg-[#1FFCB3] w-full xl:min-h-[256px]"
          style={{
            // top: "425px",
            // left: "416px",
            // width: "1400px",
            // height: "265px",
            boxShadow: "0px 3px 6px #00000029",
            objectFit: "fill",
          }}
        >
          <CAChart dataset={chartData} />
        </div>
      ) : (
        ""
      )}
      <div className="flex justify-between w-full flex-wrap items-center my-5">
        <StatCard
          customStyle={"my-5"}
          title={`${caAnnuel.toFixed(2)} €`}
          description={"CA Annuel"}
          icon={money}
        />
        <StatCard
          customStyle={"my-5"}
          title={resaCompletee}
          description={"Résa complètes"}
          icon={calendar2}
        />
        <StatCard
          customStyle={"my-5"}
          title={canceledResa}
          description={"Résa annulées"}
          icon={creative}
        />
      </div>
      <div className="mt-10 box-border border-2 shadow-lg w-full">
        <Box>
          <MaterialReactTable
            columns={columns}
            initialState={{ density: "compact" }}
            data={tableData}
            enableColumnActions={false}
            enableSorting={false}
            enablePagination={false}
            enableBottomToolbar={false}
            enableTopToolbar={false}
            tableInstanceRef={tableInstanceRef}
            renderDetailPanel={({ row }) => (
              <Box
                sx={{
                  display: "grid",
                  margin: "auto",
                  // gridTemplateColumns: "1fr 1fr",
                  width: "100%",
                }}
                className="lg:justify-center"
              >
                <div className="flex flex-col">
                  <Typography
                    variant="h6"
                    sx={{ color: "#2F3B59", fontWeight: "bold" }}
                  >
                    A. Informations personnelles
                  </Typography>
                  {/* <p>{row?.original}</p> */}
                  <div className="flex flex-col lg:flex-row lg:gap-4 mt-2  justify-between">
                    <Typography>
                      <span className="text-[#2F3B59] font-bold ml-4">
                        Nom:{" "}
                      </span>
                      {row?.original?.user?.name?.last}
                    </Typography>
                    <Typography>
                      <span className="text-[#2F3B59] font-bold ml-4 lg:ml-0">
                      Prénom:{" "}
                      </span>
                      {row?.original?.user?.name?.first}
                    </Typography>
                  </div>
                  <div className="flex flex-col lg:flex-row lg:gap-4 lg:mt-2  justify-between">
                    <Typography>
                      <span className="text-[#2F3B59] font-bold ml-4">
                        Email:{" "}
                      </span>
                      {row?.original?.user?.email}
                    </Typography>
                    <Typography>
                      <span className="text-[#2F3B59] font-bold ml-4 lg:ml-0">
                        Phone:{" "}
                      </span>
                      {row?.original?.user?.phoneNumber}
                    </Typography>
                  </div>
                  <div className="flex flex-col lg:flex-row lg:gap-4 lg:mt-2  justify-between">
                    <Typography>
                      <span className="text-[#2F3B59] font-bold ml-4">
                        Status:{" "}
                      </span>
                      {row?.original?.user?.disabled ? 
                    "Inactif"
                    : (row?.original?.user?.disabled === false ? "Actif" : "Supprimé")}
                    </Typography>
                    <Typography>
                      <span className="text-[#2F3B59] font-bold ml-4 lg:ml-0">
                        Langue:{" "}
                      </span>
                      {row?.original?.user?.language}
                    </Typography>
                  </div>
                  <div className="flex flex-col lg:flex-row lg:gap-4 lg:mt-2  justify-between">
                    <Typography>
                      <span className="text-[#2F3B59] font-bold ml-4">
                        Newsletter:{" "}
                      </span>
                      {row?.original?.user?.newsLetterSub
                        ? "Active"
                        : "Inactive"}
                    </Typography>
                    <Typography>
                      <span className="text-[#2F3B59] font-bold ml-4 lg:ml-0">
                      Réception facture:{" "}
                      </span>
                      {row?.original?.user?.invoiceSub ? "Active" : "Inactive"}
                    </Typography>
                  </div>
                </div>
                <br />
                <div className="flex flex-col mt-2">
                  <Typography
                    variant="h6"
                    sx={{ color: "#2F3B59", fontWeight: "bold" }}
                  >
                    B. Adresses
                  </Typography>
                  <div className="flex flex-col  lg:gap-4 mt-2  justify-between">
                    {row?.original?.user?.billingAddress?.map((addr, index) => {
                      return (
                        <div
                        className="lg:flex lg:flex-row lg:gap-4 lg:mt-2  lg:justify-between mb-4"
                          key={addr?._id}
                        >
                        
                          <Typography>
                            <span className="text-[#2F3B59] font-bold ml-4">
                              Type:{" "}
                            </span>
                            {addr?.type === "INDIV" ? "Perso" : "Pro"}
                          </Typography>
                          <Typography>
                            <span className="text-[#2F3B59] font-bold ml-4 lg:ml-0">
                              Adresse:{" "}
                            </span>
                            {addr?.address}
                          </Typography>
                          <Typography>
                            <span className="text-[#2F3B59] font-bold ml-4">
                              Code Postal:{" "}
                            </span>
                            {addr?.zipCode}
                          </Typography>
                          <Typography>
                            <span className="text-[#2F3B59] font-bold ml-4 lg:ml-0">
                              Ville:{" "}
                            </span>
                            {addr?.city}
                          </Typography>
                          <Typography>
                            <span className="text-[#2F3B59] font-bold ml-4">
                              Pays:{" "}
                            </span>
                            {addr?.country}
                          </Typography>
                        
                        </div>
                      );
                    })}
                  </div>
                </div>
                <br />
                <div className="flex flex-col mt-2">
                  <Typography
                    variant="h6"
                    sx={{ color: "#2F3B59", fontWeight: "bold" }}
                  >
                    C. Moyens de paiement
                  </Typography>
                  <div className="flex flex-col mt-2">
                    <Typography>
                      <span className="text-[#2F3B59] font-bold ml-4">
                        1- Wallets:
                      </span>
                    </Typography>
                    {row?.original?.user?.wallets?.map((wallet, index) => {
                      // // console.log(index);
                      return (
                        <div
                          className="flex flex-col lg:flex-row lg:gap-4 mt-2  justify-between"
                          key={wallet?._id}
                        >
                          <Typography>
                            <span className="text-[#2F3B59] font-bold ml-6 underline">
                              wallet-{index + 1}:
                            </span>
                          </Typography>
                          <Typography>
                            <span className="text-[#2F3B59] font-bold ml-8 lg:ml-8">
                              WalletExternalID:{" "}
                            </span>
                            {wallet?.walletExternalId}
                          </Typography>
                          <Typography>
                            <span className="text-[#2F3B59] font-bold ml-8 lg:ml-0">
                              Devise:{" "}
                            </span>
                            {wallet?.currency}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex flex-col mt-2">
                    <Typography>
                      <span className="text-[#2F3B59] font-bold ml-4">
                        2- Cartes bancaires:
                      </span>
                    </Typography>
                    {row?.original?.user?.cards?.map((card, index) => {
                      return (
                        <div
                          className="flex flex-col lg:flex-row lg:gap-4 mt-2  justify-between"
                          key={card?._id}
                        >
                          <Typography>
                            <span className="text-[#2F3B59] font-bold ml-6 underline">
                              carte-{index + 1}:
                            </span>
                          </Typography>
                          <Typography>
                            <span className="text-[#2F3B59] font-bold ml-8 lg:ml-8">
                              Alias:{" "}
                            </span>
                            {card?.alias}
                          </Typography>
                          <Typography>
                            <span className="text-[#2F3B59] font-bold ml-8 lg:ml-0">
                              CardExternalID:{" "}
                            </span>
                            {card?.cardExternalId}
                          </Typography>
                          <Typography>
                            <span className="text-[#2F3B59] font-bold ml-8 lg:ml-0">
                              Devise:{" "}
                            </span>
                            {card?.currency}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Box>
            )}
            positionExpandColumn="first"
            muiTableHeadCellProps={{
              sx: {
                fontSize: 15,
                fontWeight: "bold",
                color: "rgba(47, 59, 89, 1)",
              },
            }}
          />
          {tableInstanceRef.current && (
            <div className="mt-8 mb-4 grid grid-col justify-items-center">
              <Button
                className="w-32 lsm:w-72 text-xs  text-[#2F3B59] text-center uppercase"
                sx={{
                  // width: 360,
                  backgroundColor: "#1FFCB3",
                  // textAlign: "center",
                  // textTransform: "uppercase",
                  // cursor: "pointer",
                  // fontSize: 15,
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "darkgreen",
                    color: "white",
                  },
                  // color: "rgba(47, 59, 89, 1)",
                }}
                onClick={() => {
                  navigate("/ecapsule-admin/reservations", { replace: true });
                }}
              >
                Voir toutes les reservations
              </Button>
              <MRT_ToolbarAlertBanner
                stackAlertBanner
                table={tableInstanceRef.current}
              />
            </div>
          )}
        </Box>
      </div>
    </div>
  );
};

export default AdminDashboard;
