import BurgerMenu from "../ui/BurgerMenu";
import { useEffect, useState } from "react";
import PopupCOntainer from "./PopupContainer";
import MenuHeader from "./MenuHeader";
import { Link } from "react-router-dom";
import { useOutsideClick } from "../../utils/hooks";
import PopupNewAccount from "./PopupNewAccount";
import PopupLogin from "./PopupLogin";
import PopupResetPassword from "./PopupResetPassword";

const publicUrl = process.env.PUBLIC_URL;
const logo = publicUrl + "/logo@2x.png";

const AuthPopUp = ({ openType = null, closeMenu = () => {}, inReservation }) => {
  const [PopUpNewAcc, setPopUpNewAcc] = useState(false);
  const [PopUpLog, setPopUpLog] = useState(false);
  const [PopUpReset, setPopUpReset] = useState(false);

  const hidePopUpNewAccount = () => {
    setPopUpNewAcc(false);
    document.body.classList.remove("overflow-hidden");
  };
  const hidePopUpLogin = () => {
    setPopUpLog(false);
    document.body.classList.remove("overflow-hidden");
  };
  const hidePopUpReset = () => {
    setPopUpReset(false);
    document.body.classList.remove("overflow-hidden");
  };

  const onValidated = () => {
    setPopUpNewAcc(false);
    setPopUpLog(true);
  };

  const onConnectPress = () => {
    setPopUpNewAcc(false);
    setTimeout(() => {
      setPopUpLog(true);
    }, 500);
  };

  const onCreateAccount = () => {
    setPopUpLog(false);
    setTimeout(() => {
      setPopUpNewAcc(true);
    }, 500);
  };

  const onResetPwd = () => {
    setPopUpLog(false);
    setTimeout(() => {
      setPopUpReset(true);
    }, 500);
  };

  useEffect(() => {
    if (openType === "login") {
      // setType("login");
      setPopUpLog(true);
    } else if (openType === "inscription") {
      // setType("inscription");
      setPopUpNewAcc(true);
    }
    if (openType === null) {
      setPopUpLog(false)
      setPopUpNewAcc(false)
      setPopUpReset(false)
    }
  }, [openType]);

  return (
    <div>
      {PopUpNewAcc && (
        <PopupNewAccount
          type={"inscription"}
          openModal={hidePopUpNewAccount}
          closeMenu={closeMenu}
          isOpened={PopUpNewAcc}
          inMobile={true}
          onValidated={onValidated}
          onConnect={onConnectPress}
          inReservation={inReservation}
        />
      )}
      {PopUpLog && (
        <PopupLogin
          type={"login"}
          openModal={hidePopUpLogin}
          closeMenu={closeMenu}
          isOpened={PopUpLog}
          inMobile={true}
          onCreateAccount={onCreateAccount}
          onResetPwd={onResetPwd}
          inReservation={inReservation}
        />
      )}
      {PopUpReset && (
        <PopupResetPassword
          type={""}
          openModal={hidePopUpReset}
          closeMenu={closeMenu}
          isOpened={PopUpReset}
          inMobile={true}
          inReservation={inReservation}
          // onCreateAccount={onCreateAccount}
          // onResetPwd={onResetPwd}
        />
      )}
    </div>
  );
};

export default AuthPopUp;
