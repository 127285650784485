
import { Link } from "react-router-dom";
import {erroPageText} from "../../data/data"
const notFound = ()=>{
    return(
        <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
	<div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
		<div className="max-w-md text-center">
			<h2 className="mb-8 font-extrabold text-9xl text-lightGreen dark:text-gray-600">
				<span className="sr-only">Error</span>404
			</h2>
			<p className="text-2xl font-semibold md:text-3xl">{erroPageText.text1[0]}</p>
			<p className="mt-4 mb-8 dark:text-gray-400">{erroPageText.text2[0]}</p>
			<Link  to="/" className="px-8 py-3 font-semibold rounded btn-principal text-white">{erroPageText.btnText[0]}</Link>
		</div>
	</div>
</section>
    )
}

export default notFound;