import { Outlet, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
// import {ChevronRight} from "@mui/icons-material/ChevronRight";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Dashboard from "@mui/icons-material/Dashboard";
import LocalOffer from "@mui/icons-material/LocalOffer";
import BookOnline from "@mui/icons-material/BookOnline";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
const publicUrl = process.env.PUBLIC_URL;
const imgUrl = publicUrl + "/logo.png";

const Admin = () => {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate();

  const logout = async (refreshToken) => {
    try {
      const res = await axiosPrivate.post("admin/auth/logout", {
        refreshToken,
      });
      if (res) {
        const i18nextLngValue = localStorage.getItem('i18nextLng');
        const CookieConsent = localStorage.getItem("CookieConsent");
        localStorage.clear();
        if(CookieConsent){
          localStorage.setItem('CookieConsent', CookieConsent);
        } 
        localStorage.setItem('i18nextLng', i18nextLngValue);
        navigate("/ecapsule-login", { replace: true });
      }
    } catch (error) {
      return;
    }
  };

  return (
    <>
      <div className="flex h-full">
        <div className="flex min-h-screen flex-col items-center w-1/5 bg-[#2F3B59] xs:w-14  semimd:w-1/5 overflow-hidden text-gray-400 bg-gray-900 rounded">
          <div
            className="semimd:flex semimd:items-center semimd:justify-center semimd:mt-6 semimd:w-full semimd:px-6 hidden semimd:mt-16"
            href="#"
          >
            <NavLink to="/ecapsule-admin/dashboard">
              <img className="pt-2 lg:w-48" src={imgUrl} alt="logo" />
            </NavLink>
          </div>
          <div className="flex flex-col items-center mt-3 w-2 semimd:mt-8 semimd:w-full px-2 semimd:border-t semimd:border-gray-700 semimd:items-start">
            <NavLink
              className="focus:bg-lightGreen focus:text-white flex items-center justify-center  w-12 h-12 mt-2 rounded  hover:text-gray-300 sm:w-full sm:h-12 sm:px-3"
              href="#"
              to="/ecapsule-admin/dashboard"
            >
              <Dashboard className="semimd:mr-2 w-6 h-6" />
              <span className="hidden semimd:block semimd:ml-2 semimd:text-sm semimd:font-bold text-white">
                Dashboard
              </span>
              <ChevronRight className="invisible semimd:visible  semimd:ml-6" />
            </NavLink>
            <NavLink
              className="focus:bg-lightGreen focus:text-white flex items-center justify-center w-12 h-12 mt-2 rounded  hover:text-gray-300 sm:w-full sm:h-12 sm:px-3"
              href="#"
              to="/ecapsule-admin/coupons"
            >
              <LocalOffer className="semimd:mr-2 w-6 h-6" />
              <span className="hidden semimd:block semimd:ml-2 semimd:text-sm semimd:font-bold">
                Coupons
              </span>
              <ChevronRight className="invisible semimd:visible  semimd:ml-10" />
            </NavLink>
            <NavLink
              className="focus:bg-lightGreen focus:text-white flex items-center justify-center  w-12 h-12 mt-2 rounded  hover:text-gray-300 sm:w-full sm:h-12 sm:px-3"
              href="#"
              to="/ecapsule-admin/reservations"
            >
              <BookOnline className="semimd:mr-2 w-6 h-6" />
              <span className="hidden semimd:block semimd:ml-2 semimd:text-sm semimd:font-bold">
                Reservations
              </span>
              <ChevronRight className="invisible semimd:visible  semimd:ml-3" />
            </NavLink>
          </div>
          <div
            className="flex items-center justify-center cursor-pointer  w-16 h-16 mt-auto bg-gray-800  hover:text-gray-300 sm:w-full sm:h-12 sm:px-3"
            onClick={(e) => {
              e.preventDefault();
                const user = JSON.parse(localStorage.getItem("user"));
                const refreshToken = user.refreshToken;
                logout(refreshToken);
            }}
          >
            <MeetingRoomIcon className="semimd:mr-2 w-6 h-6" />
            <span className="hidden semimd:block semimd:ml-2 semimd:text-sm semimd:font-medium">
              DECONNEXION
            </span>
          </div>
        </div>
        <div className="pages w-4/5 mt-4">
          <Outlet />
        </div>

        
      </div>
    </>

    // <div className="flex">
    //   <div className="bg-darkBlue h-screen w-1/5">
    //     <div className="admin-links text-white flex flex-col  ">
    //       <div className="mb-8 mt-16 px-10">
    //         <img className="pt-5 sm:pt-0" src={imgUrl} alt="logo" />
    //       </div>
    //       <NavLink
    //         className="flex justify-between items-center mt-2 px-10"
    //         to="/ecapsule-admin/dashboard"
    //       >
    //         <span className="flex items-center">
    //           <Dashboard className="mr-2" /> Dashboard
    //         </span>
    //         <ChevronRight />
    //       </NavLink>
    //       <NavLink
    //         className="mt-4  flex justify-between items-center mt-2 px-10"
    //         to="/ecapsule-admin/coupons"
    //       >
    //         <span className="flex items-center">
    //           <LocalOffer className="mr-2" /> Coupons
    //         </span>
    //         <ChevronRight />
    //       </NavLink>
    //       <NavLink
    //         className="mt-4  flex justify-between items-center mt-2 px-10"
    //         to="/ecapsule-admin/reservations"
    //       >
    //         <span className="flex items-center">
    //           <BookOnline className="mr-2" /> Reservations
    //         </span>
    //         <ChevronRight />
    //       </NavLink>
    //     </div>

    //     <div className="mt-[560px] mt-16 px-10 flex items-center">
    //       <Button
    //         btnContent={"Deconnexion"}
    //         customStyle={"btn-principal mb-6"}
    //         onClick={() => {
    //           localStorage.clear();
    //           navigate('/', {replace: true})
    //         }}
    //       />
    //     </div>
    //   </div>

    //   <div className="pages w-full mt-16 px-16">
    //     <Outlet />
    //   </div>
    // </div>
  );
};

export default Admin;