import { useState } from 'react';
import { ShowBox,HideBox } from '../../data/Icons';

const PasswordField = ({password,setPassword,autoComplete}) => {

  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <div className="relative">
      <input 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        autoComplete={`${!autoComplete?"current-password":autoComplete}`}
        className="border border-[#8c8f94]  text-gray-900 text-sm rounded focus:border-darkGreen focus:outline-none block w-full p-2.5 mb-4" 
        type={passwordShown ? "text" : "password"} 
      />
      <span 
        onClick={() => setPasswordShown(!passwordShown)}  
        className="absolute cursor-pointer -translate-y-1/2 top-1/2 right-2"
      >
        {!passwordShown ? <ShowBox/> : <HideBox/>}
      </span>
    </div>
  );
};

export default PasswordField;