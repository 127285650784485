import axios from "axios";

/// "http://localhost:5000/api/v1/"
const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_FILE = process.env.REACT_APP_PUBLIC_URL;
export let userToken = JSON.parse(localStorage.getItem('user'))?.accessToken || '';

// export const API_KEY = ""

const publicRequest = axios.create({
  baseURL: BASE_URL,
});

publicRequest.interceptors.request.use(
  config => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
      const API_KEY = process.env.REACT_APP_SERVER_API_KEY
      // console.log('API_KEY : ', API_KEY)
      config.headers['apikey'] = API_KEY;
      // if (!config.headers['Authorization'] && !config.url.includes("auth/logout") && !config.url.includes("admin/auth/logout")) {
      //     config.headers['Authorization'] = `Bearer ${currentUser?.accessToken}`;
      // }
      return config;
  }, (error) => Promise.reject(error)
);

const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    // Authorization: `Bearer ${userToken}`,
    'content-type': 'application/json',
  }
});

userRequest.interceptors.request.use(
  config => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
      const API_KEY = process.env.REACT_APP_SERVER_API_KEY
      // console.log('API_KEY : ', API_KEY)
      config.headers['apikey'] = API_KEY;
      if (!config.headers['Authorization'] && !config.url.includes("auth/logout") && !config.url.includes("admin/auth/logout")) {
          config.headers['Authorization'] = `Bearer ${currentUser?.accessToken}`;
      }
      return config;
  }, (error) => Promise.reject(error)
);

const userRequestFiles = axios.create({
  baseURL: BASE_URL_FILE,
  headers: {
    Authorization: `Bearer ${userToken}`,
    'content-type': 'multipart/form-data',
  },
});

userRequestFiles.interceptors.request.use(
  config => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
      const API_KEY = process.env.REACT_APP_SERVER_API_KEY
      // console.log('API_KEY : ', API_KEY)
      config.headers['apikey'] = API_KEY;
      config.headers['content-type'] = 'multipart/form-data'
      if (!config.headers['Authorization'] && !config.url.includes("auth/logout") && !config.url.includes("admin/auth/logout")) {
          config.headers['Authorization'] = `Bearer ${currentUser?.accessToken}`;
          
      }
      return config;
  }, (error) => Promise.reject(error)
);

const refreshAccessToken = async (refreshToken, onSessionExpired = () => {}) => {

  try {
    const API_KEY = process.env.REACT_APP_SERVER_API_KEY
    const response = await axios.post(BASE_URL + 'auth/accessToken', { refreshToken }, {
      headers: {
        apikey: API_KEY,
      }
    });
    const { accessToken } = response.data;


    const user = JSON.parse(localStorage.getItem("user"));
    user.accessToken = accessToken;
    localStorage.setItem('user', JSON.stringify(user));

    return accessToken;
  } catch (error) {
    if (onSessionExpired) {
      onSessionExpired()
    }
    // return "";
    // logout
    // throw new Error('Could not refresh access token');
  }
};

// const RM = {
export {publicRequest}
export {userRequest}
export {userRequestFiles}
export {refreshAccessToken}
// }

// export default RM;

