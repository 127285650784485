import data from "../../data/data";
import { Link } from "react-router-dom";
import MultiLanguage from "../ui/MultiLanguage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useOutsideClick } from "../../utils/hooks";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { EmailIcon, PhoneIcon } from "../../data/Icons";
import { useTranslation } from "react-i18next";

const {
  fr: { menuBeforeConnexion, menuAfterConnexion },
} = data;

const MenuHeader = ({
  handleClickMenuItem,
  desktopOrMobile,
  open,
  closeMenu,
}) => {
  const {t} = useTranslation();
  const [dataMenu, setDataMenu] = useState([]);
  const [showDropMenu, setDropMenu] = useState(false);
  const [showDropMenu2, setDropMenu2] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  // hover open dropDownMenu:

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    setIsDesktop(mediaQuery.matches);

    const handleMediaQueryChange = (event) => {
      setIsDesktop(event.matches);
    };

    mediaQuery.addListener(handleMediaQueryChange);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const openDropDownOnMouseEnter = (id) => {
    if (isDesktop) {
      dropDowns(id);
    }
  };

  const closeDropDownOnMouseLeave = (id) => {
    if (isDesktop) {
      closeDropDown(id);
    }
  };

  // Handle click outside
  const handleClickOutside = (event) => {
    if (ref.current && ref.current && !ref.current.contains(event.target)) {
      setDropMenu(false);
    }
    if (ref2.current && ref2.current && !ref2.current.contains(event.target)) {
      setDropMenu2(false);
    }
  };
  const ref = useOutsideClick(handleClickOutside);
  const ref2 = useOutsideClick(handleClickOutside);

  // Logout

  const logout = async (refreshToken) => {
    try {
      const res = await axiosPrivate.post("auth/logout", { refreshToken });
      if (res) {
         const i18nextLngValue = localStorage.getItem('i18nextLng');
         const CookieConsent = localStorage.getItem("CookieConsent");
         localStorage.clear();
         if(CookieConsent){
          localStorage.setItem('CookieConsent', CookieConsent);
        } 
         localStorage.setItem('i18nextLng', i18nextLngValue);
        setDataMenu(menuBeforeConnexion);
        navigate("/", { replace: true });
        if (closeMenu) {
          closeMenu();
        }
      }
    } catch (error) {
      return;
    }
  };

  // show DropDown
  const dropDowns = (item) => {
    if (item === 4) {
      setDropMenu(!showDropMenu);
    } else if (item===1955) {
      setDropMenu2(!showDropMenu2);
    }
  };

  // Close dropDown
  const closeDropDown = (item) => {
    if (item === 4) {
      setDropMenu(false);
    } else if (item===1955) {
      setDropMenu2(false);
    }
  };

  useEffect(() => {
    const dataUser = JSON.parse(localStorage.getItem("user"));

    setDataMenu(dataUser ? menuAfterConnexion : menuBeforeConnexion);
  }, [localStorage.getItem("user")]);

  return (
    <ul
      className={
        desktopOrMobile === "desktop"
          ? "menu items-menu  py-2  desktop-menu flex gap-x-10"
          : `menuMobile items-menu z-10 pt-16 pb-10 pl-10  ${
              open ? "lg:hidden" : "hidden"
            }`
      }
    >
      {/* DropDownMenu */}
      {dataMenu.map((item) => {
        return (
          <li
            key={item.id}
            className="mb-4 lg:mb-4  font-medium "
            ref={item.id === 4 ? ref : (item.id===1955)?ref2:null}
            onMouseEnter={() => {
              openDropDownOnMouseEnter(item.id);
            }}
            onMouseLeave={() => closeDropDownOnMouseLeave(item.id)}
          >
            {item.title === "dropDownMenu" ? (
              <div key={item.id} className={`dropDownItems ${item.id === 4?"relative":""}`}>
                {
                  <span
                    onClick={() => {
                      dropDowns(item.id);
                      
                    }}
                    className="cursor-pointer relative "
                  >
                    {item.id === 4
                      ? JSON.parse(localStorage.getItem("user"))?.email || ""
                      : t("menu."+item.subtitle)}
                    <i className="ml-1 arrow-down"></i>
                  </span>
                }
                <div
                  className={`dropDownItems ${item.id === 4?"min-w-full":""} transition-500ms slide-in-fwd-top   py-2 lg:py-20 lg:px-5 xl:px-10 lg:z-10 lg:absolute lg:l-0  lg:bg-darkBlue flex-col gap-y-3 
                  ${
                    item.id === 4
                      ? !showDropMenu
                        ? "hidden"
                        : "flex"
                      : !showDropMenu2
                      ? "hidden"
                      : "flex"
                  }
                  ${item.id!==4?"lg:-ml-10 xl:px-5":""}
                  `}
                  
                >
                  {item.content.map((menuItem) => {
                    if (item.id === 4) {
                      return menuItem.link !== "#" ? (
                        <Link
                          onClick={() => {
                            if (closeMenu) {
                              closeMenu();
                            }
                            setDropMenu(false);
                            setDropMenu2(false);
                          }}
                          className="inline-block lg:whitespace-nowrap"
                          to={menuItem.link}
                          key={menuItem.id}
                        >
                          {t("menu."+menuItem.title.toLowerCase())}
                        </Link>
                      ) : (
                        <span
                          className="cursor-pointer inline-block"
                          key={menuItem.id}
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              menuItem.title.toLocaleLowerCase() ===
                              "deconnexion"
                            ) {
                              const user = JSON.parse(
                                localStorage.getItem("user")
                              );
                              const refreshToken = user.refreshToken;
                              logout(refreshToken);
                            }
                          }}
                        >
                          {t("menu."+menuItem.title.toLowerCase())}
                        </span>
                      );
                    }
                    //DropDOwnMenu2
                    else {
               
                      return (
                        <a
                         href={menuItem.title === "phone" ? "tel:+33582950969" : "mailto:contact@ecapsule.fr"}
                          key={menuItem.id}
                          className="flex justify-start items-center gap-2 mb-2 assistance" >
                          <span
                            className={`${
                              menuItem.title === "phone" ? "vibrate-1" : ""
                            }`}
                          >
                            {menuItem.title==="phone"&&<PhoneIcon/>}
                            {menuItem.title==="email"&&<EmailIcon/>}
                          </span>
                          <span className="font-medium">
                            {menuItem.content}
                          </span>
                        </a>
                      );
                    }
                  })}
                </div>
              </div>
            ) : item.link !== "#" ? (
              <Link
                to={item.link}
                key={item.id}
                onClick={(e) => {
                  if (closeMenu) {
                    closeMenu();
                  }
                }}
              >
                {t("menu."+item.title.toLowerCase())}
              </Link>
            ) : (
              <span
                className={`cursor-pointer ${item.title.toLowerCase()}`}
                onClick={(e) => {
                  e.preventDefault();
                  if (item.key.toLowerCase() === "inscription") {
                    handleClickMenuItem("inscription");
                    document.body.classList = "overflow-hidden";
                  } else if (item.key.toLowerCase() === "connexion") {
                    handleClickMenuItem("login");
                    document.body.classList = "overflow-hidden";
                  }
                }}
              >
                
                {t("menu."+item.title.toLowerCase())}
              </span>
            )}
          </li>
        );
      })}

      <li>
        <MultiLanguage />
      </li>
    </ul>
  );
};
export default MenuHeader;
