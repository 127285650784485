
import { faqGeneral, faqGeneralEN, faqUtilisation,faqUtilisationEN, faqReservations,faqReservationsEN, } from "../../data/dataFaq";
import { useTranslation } from "react-i18next";

import FaqComponent from "react-faq-component";



const styles = {
  //bgColor: 'rgba(255,255,244,1)',
  titleTextColor: "tropazBlue",
  rowTitleColor: "darkBlue",
  rowContentColor: 'black',
   arrowColor: "darkBlue",
   rowContentTextSize:"0.9rem",
   rowTitleTextSize:"1.1rem",
   titleTextSize:"1.5rem"
};

const config = {
  animate: true,
  tabFocus: true,
  //openOnload: 0,
   
};



const Faq = () => {
  const {t,i18n}= useTranslation();

  
  return(
    <div className=" width-padding mb-10 sm:max-w-80 mx-auto" id="faq">
      <h2 className="text-2xl sm:text-2xl font-bold text-darkBlue">
        {t("faq.heading")}
      </h2>
     <div className="mt-2 ">
     <FaqComponent
                data={i18n.language==="fr" || i18n.language!=="en"? faqGeneral():faqGeneralEN()}
                styles={styles}
                config={config}
            />
     </div>
     <div className="mt-2">
     <FaqComponent
                data={i18n.language==="fr" || i18n.language!=="en"? faqUtilisation():faqUtilisationEN()}
                styles={styles}
                config={config}
            />
     </div>

     <div className="mt-2">
     <FaqComponent
                data={i18n.language==="fr" || i18n.language!=="en"? faqReservations():faqReservationsEN()}
                styles={styles}
                config={config}
            />
     </div>
    </div>
  );
  
};

export default Faq;
