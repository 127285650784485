import {useState } from "react";
import { DateTime } from "luxon";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Toast from "../../ui/Toast";
import Loader from "../../ui/Loader";
import { useTranslation } from "react-i18next";

export const AddCoupon = ({ open, columns, onClose, onSubmit }) => {
  const {t}= useTranslation();
  const [libelle, setLibelle] = useState("");
  const [value, setValue] = useState(15);
  const [startDate, setStartDate] = useState(
    DateTime.fromISO(DateTime.now().toISO())
      .setLocale("fr")
  );

  const [endDate, setEndDate] = useState(
    DateTime.fromISO(DateTime.now().toISO())
      .setLocale("fr")
  );
  const [status, setStatus] = useState("1");


  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [responseType, setResponseType] = useState("success");
  const time = 5000;

  const [loader,setLoader]= useState(false)

  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const axiosPrivate = useAxiosPrivate();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = async () => {
    setLoader(true)
    try {
      const values = {
        libelle,
        value,
        startDate,
        endDate,
        status: status !== "Actif" ? 0 : 1,
      };
      const res = await axiosPrivate.post("codepromo", values);
      if (res) {
        // setAlertSeverity("success");
        displayToast("Code promo ajouté avec succès!", "success");
        // setOpenAlert(true);
        onSubmit({
          id: res.data.coupon._id,
          libelle: res.data.coupon.libelle,
          value: res.data.coupon.value,
          startDate: DateTime.fromISO(res.data.coupon.startDate)
            .setLocale("fr")
            .toLocaleString(DateTime.DATE_SHORT),
          endDate: DateTime.fromISO(res.data.coupon.endDate)
            .setLocale("fr")
            .toLocaleString(DateTime.DATE_SHORT),
          status: res.data.coupon.status === 1 ? "Actif" : "Inactif",
        });
        setLoader(false)
        onClose();
      }
    } catch (error) {
      // setAlertSeverity("error");
      setLoader(false)
      displayToast(t(error.response.data.message), "error");
      // setOpenAlert(true);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        aria-labelledby="responsive-dialog-title"
        key={'addCoupon'}
      >
        <DialogTitle
          textAlign="center"
          className="text-darkBlue text-2xl xl:text-3xl font-semibold"
          id="responsive-dialog-title"
          key={"responsive-dialog-title"}
        >
          Ajouter un nouveau code promo
        </DialogTitle>
        <DialogContent key={"responsive-dialog-content"}>
          <form onSubmit={(e) => e.preventDefault()} key={"form"}>
            <Stack
              sx={{
                width: "100%",
                minWidth: { xs: "300px", sm: "360px", md: "400px" },
                gap: "1.5rem",
                marginTop: "5px",
              }}
            >
              {columns.map((column) => {
                switch (column.id) {
                  case "startDate":
                    return (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale="fr"
                        key={'startDate-local'}
                      >
                        <DatePicker
                          key={column.accessorKey}
                          label={column.header}
                          format="dd/MM/yyyy"
                          disablePast
                          // placeholder="JJ/MM/AAAA"
                          // minDate={DateTime.now()}
                          closeOnSelect
                          onChange={(e) => {
                            setStartDate(DateTime.fromISO(e).setLocale("fr"));
                          }}
                          value={startDate}
                        />
                      </LocalizationProvider>
                    );
                  case "endDate":
                    return (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale="fr"
                        key={'endDate-local'}
                      >
                        <DatePicker
                          key={column.accessorKey}
                          // label={column.header}
                          format="dd/MM/yyyy"
                          closeOnSelect
                          disablePast
                          // minDate={DateTime.now()}
                          onChange={(e) => {
                            setEndDate(DateTime.fromISO(e).setLocale("fr"));
                          }}
                          value={endDate}
                        />
                      </LocalizationProvider>
                    );
                  case "status":
                    return (
                      <FormControl key={'status-formcontrol'}>
                        <InputLabel id={column.header}>Status</InputLabel>
                        <Select
                          label={column.header}
                          key={column.accessorKey}
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <MenuItem value={"Actif"} key='actif'>Actif</MenuItem>
                          <MenuItem value={"Inactif"} key='inactif'>Inactif</MenuItem>
                        </Select>
                      </FormControl>
                    );
                  case "value":
                    return (
                      <>
                        <FormControl key={'value-formcontrol'}>
                          <InputLabel id={column.header}>Valeur</InputLabel>
                          <Select
                            label={column.header}
                            key={column.accessorKey}
                            labelId={column.id}
                            value={value}
                            onChange={(e) => {
                              setValue(parseInt(e.target.value));
                            }}
                          >
                            <MenuItem value={15} key='15'>15</MenuItem>
                            <MenuItem value={30} key='30'>30</MenuItem>
                            <MenuItem value={45} key='45'>45</MenuItem>
                            <MenuItem value={60} key='60'>60</MenuItem>
                          </Select>
                        </FormControl>
                      </>
                    );
                  case "id":
                    break;
                  default:
                    return (
                      <TextField
                        key={column.accessorKey}
                        label={column.header}
                        name={column.accessorKey}
                        type="text"
                        onBlur={(e) => {
                          setLibelle(e.target.value);
                        }}
                      />
                    );
                }
              })}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: "1.25rem" }} key={"responsive-dialog-actions"}>
          <Button
          key={'cancel'}
            sx={{
              // backgroundColor: "lightgreen",
              textAlign: "center",
              textTransform: "uppercase",
              cursor: "pointer",
              fontSize: 15,
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "red",
                color: "white",
              },
              // color: "rgba(47, 59, 89, 1)",
              marginBottom: 4,
              marginTop: 2,
            }}
            onClick={onClose}
          >
            Annuler
          </Button>
          <Button
          key={'add'}
            sx={{
              backgroundColor: "lightgreen",
              textAlign: "center",
              textTransform: "uppercase",
              cursor: "pointer",
              fontSize: 15,
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "darkgreen",
                color: "white",
              },
              color: "rgba(47, 59, 89, 1)",
              marginBottom: 4,
              marginTop: 2,
            }}
            onClick={handleSubmit}
            variant="contained"
          >
            Ajouter
          </Button>
        </DialogActions>
        {loader && (
          <div className="flex items-center justify-center my-2 mb-2" id='loader'>
            <Loader />
          </div>
        )}
      </Dialog>
      {openToast && (
        <Toast
          responseType={responseType}
          message={message}
          open={openToast}
          close={handleCloseToast}
          duration={time}
        />
      )}
    </>
  );
};

export default AddCoupon;
