import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Cgu = ({full}) => {
  const {t}= useTranslation();
  useEffect(()=>{
    return () => {
      // This code runs when the component unmounts
      document.body.classList.remove("overflow-y-hidden");
    };
  })
  return (
    <div className={` my-10 ${full?"px-4":"sm:max-w-80 width-padding"} mx-auto`} id="cgu">
      <h1 className="text-lg font-bold">
        {t("cgu.title")}
      </h1>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 1.</span> {t("cgu.article1")}
        </h3>
        <p>
        {t("cgu.article1Intro")}
        </p>
        <ul>
          <li>
            <span className="font-bold text-darkBlue">{t("cgu.cgu")}:</span> {t("cgu.cguText")}
          </li>
          <li>
            <span className="font-bold text-darkBlue">{t("cgu.donnes")}:</span> {t("cgu.donnesText")}
          </li>
          <li>
            <span className="font-bold text-darkBlue">Ecapsule:</span> {t("cgu.ecapsuleText")}
          </li>
          <li>
            <span className="font-bold text-darkBlue">{t("cgu.etablissements")}:</span>
             {t("cgu.etablissementsText")}
          </li>
          <li>
            <span className="font-bold text-darkBlue">{t("cgu.identifiant")}:</span>
              {t("cgu.identifiantText")}
          </li>
          <li>
            <span className="font-bold text-darkBlue">
            {t("cgu.processusPaiement")}:
            </span>
             {t("cgu.processusText")}
          </li>
          <li>
            <span className="font-bold text-darkBlue">
            {t("cgu.reglementation")}:
            </span>{" "}
            {t("cgu.reglementationText")}
          </li>
          <li>
            <span className="font-bold text-darkBlue">{t("cgu.parties")}:</span> {t("cgu.partiesText")}
          </li>
          <li>
            <span className="font-bold text-darkBlue">{t("cgu.services")}:</span> {t("cgu.servicesText1")}{" "}
            <Link className="font-bold text-darkBlue" to="/">
              www.ecapsule.fr
            </Link>{" "}
            {t("cgu.servicesText2")} 
          </li>
          <li>
            <span className="font-bold text-darkBlue">{t("cgu.site")}: </span>
            {t("cgu.siteText")}{" "}
            <Link className="font-bold text-darkBlue" to="/">
              www.ecapsule.fr
            </Link>
            .
          </li>
          <li>
            <span className="font-bold text-darkBlue">{t("cgu.user")}:</span>
             {t("cgu.userText")}
          </li>
        </ul>
        <p>{t("cgu.article1Ul")}</p>
        <ul>
          <li>
          {t("cgu.articleLI1")}
          </li>
          <li>{t("cgu.articleLI2")}</li>
          <li>
          {t("cgu.articleLI3")}
          </li>
          <li>
          {t("cgu.articleLI4")}
          </li>
        </ul>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 2.</span> {t("cgu.article2")}
        </h3>
        <p>
        {t("cgu.article2Text")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 3.</span> {t("cgu.article3")}
        </h3>
        <p>
        {t("cgu.article3Text")} <Link className="font-semibold" to="/mentions-legals">
            https://www.ecapsule.fr/mentions-legals{" "}
          </Link> 
          {t("cgu.article3Text2")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 4.</span> {t("cgu.article4")}
        </h3>
        <p>
        {t("cgu.article4Text")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 5.</span> {t("cgu.article5")}
        </h3>
        <p>
        {t("cgu.article5Text")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 6.</span> {t("cgu.article6")}
        </h3>
        <p>
        {t("cgu.article6Text")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 7.</span> {t("cgu.article7")}
        </h3>
        <p>
        {t("cgu.article7UL")}</p>
          <ul>
            <li>{t("cgu.article7LI1")}</li>
            <li>
            {t("cgu.article7LI2")}
            </li>
            <li>{t("cgu.article7LI3")}</li>
            <li>
            {t("cgu.article7LI4")}
            </li>
          </ul>
          <p>
          {t("cgu.article7Text")}
          </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 8.</span> {t("cgu.article8")}
        </h3>
        <p>
        {t("cgu.article8Text")}
          <Link className="font-semibold text-darkBlue" to="/faq">
            FAQ
          </Link>
          .
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 9.</span> {t("cgu.article9")}
        </h3>
        <p>
        {t("cgu.article9UL")}
        </p>
        <ul>
          <li>
            <span className="font-bold text-darkBlue">
            {t("cgu.article9LI1")}
            </span>
          </li>
          <li>
            <span className="font-bold text-darkBlue">
            {t("cgu.article9LI2")}
            </span>{" "}
          </li>
          <li>
            <span className="font-bold text-darkBlue">
            {t("cgu.article9LI3")}
            </span>{" "}
          </li>
        </ul>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 10.</span> {t("cgu.article10")}
        </h3>
        <p>
        {t("cgu.article10Text")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 11.</span> {t("cgu.article11")}
        </h3>
        <p>
        {t("cgu.article11Text")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 12.</span> {t("cgu.article12")}
        </h3>
        <p>
        {t("cgu.article12UL")}
        </p>
        <ul>
          <li>
          {t("cgu.article12LI1")}
          </li>
          <li>
          {t("cgu.article12LI2")}
          </li>
          <li>{t("cgu.article12LI3")}</li>
          <li>
          {t("cgu.article12LI4")}{" "}
            <span className="text-darkBlue font-semibold">
              contact@minutup.com
            </span>
            .
          </li>
          <li>
          {t("cgu.article12LI5")}
          </li>
        </ul>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 13.</span> {t("cgu.article13")}
        </h3>
        <p>
        {t("cgu.article13Text")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 14.</span> {t("cgu.article14")}
        </h3>
        <h4 className="text-lightGreen font-bold mt-1 -mb-2">
        {t("cgu.article14Title1")}
        </h4>
        <p>
        {t("cgu.article14Text1")}
        </p>
        <h4 className="text-lightGreen font-bold mt-1 -mb-2">
        {t("cgu.article14Title2")}
        </h4>
        <p>
        {t("cgu.article14Text2")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 15.</span>{t("cgu.article15")}
        </h3>
        <p>
        {t("cgu.article15Text")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 16.</span> {t("cgu.article16")}
        </h3>
        <p>
        {t("cgu.article16UL")}{" "}
        </p>
        <ul>
          <li>
          {t("cgu.article16LI1")}
          </li>
          <li>
          {t("cgu.article16LI2")}
          </li>
          <li> {t("cgu.article16LI3")}</li>
          <li> {t("cgu.article16LI4")}</li>
          <li> {t("cgu.article16LI5")}</li>
          <li> {t("cgu.article16LI6")} </li>
        </ul>
        <p>
        {t("cgu.article16Text")}
        </p>
      </article>

      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 17.</span> {t("cgu.article17")}
        </h3>
        <p>
        {t("cgu.article17UL")}
        </p>
        <ul>
          <li>
          {t("cgu.article17LI1")}
          </li>
          <li>
          {t("cgu.article17LI2")}
          </li>
          <li>
          {t("cgu.article17LI3")}
          </li>
          <li>
          {t("cgu.article17LI4")}
          </li>
          <li>
          {t("cgu.article17LI5")}
          </li>
          <li>
          {t("cgu.article17LI6")}
          </li>
          <li>
          {t("cgu.article17LI7")}
          </li>
        </ul>
        <p>
        {t("cgu.article17Text1")}
        </p>
        <span className="font-medium block">Minutup SAS </span>
        <span className="font-medium block">
          5 esplanade Compans Caffarelli
        </span>
        <span className="font-medium block">31000 Toulouse </span>
        <p>
        {t("cgu.article17Text2")}{" "}
          <a className="font-semibold" href="https://www.cnil.fr/fr/plaintes">
            https://www.cnil.fr/fr/plaintes
          </a>
          . 
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 18.</span> {t("cgu.article18")}
        </h3>
        <p>
        {t("cgu.article18Text")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 19.</span> {t("cgu.article19")}
        </h3>
        <p>
        {t("cgu.article19Text")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 20.</span> {t("cgu.article20")}
        </h3>
        <p>
        {t("cgu.article20Text")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 21.</span> {t("cgu.article21")}
        </h3>
        <p>
        {t("cgu.article21Text")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 22.</span> {t("cgu.article22")}
        </h3>
        <p>
        {t("cgu.article22Text")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 23.</span> {t("cgu.article23")}
        </h3>
        <p>
        {t("cgu.article23Text")}
        </p>
      </article>
      <article>
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 24.</span> {t("cgu.article24")}
        </h3>
        <p>
        {t("cgu.article24Text")}
        </p>
      </article>
      <article className="pb-2">
        <h3 className="text-darkBlue mt-2 font-semibold text-lg">
          <span className="text-lightGreen">ARTICLE 25.</span> {t("cgu.article25")}
        </h3>
        <p>
        {t("cgu.article25Text1")}
        </p>
        <span className="font-medium block">
          Monsieur Jean-Pierre Teyssier,
        </span>
        <span className="font-medium block">MTV Médiation Tourisme Voyage</span>
        <span className="font-medium block">
          BP 80 303 - 75 823 Paris Cedex 17
        </span>
        <p>
        {t("cgu.article25Text2")}
        </p>
        <p>
        {t("cgu.article25Text3")}
        </p>
      </article>
    </div>
  );
};

export default Cgu;