import BurgerMenu from "../ui/BurgerMenu";
import { useState } from "react";
import PopupCOntainer from "./PopupContainer";
import MenuHeader from "./MenuHeader";
import { Link } from "react-router-dom";
import { useOutsideClick } from "../../utils/hooks";
import PopupNewAccount from "./PopupNewAccount";
import PopupLogin from "./PopupLogin";
import PopupResetPassword from "./PopupResetPassword";
import AuthPopUp from "./AuthPopUp";

const publicUrl = process.env.PUBLIC_URL;
const logo = publicUrl + "/logo@2x.png";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [fixMenu, setFixMenu] = useState(false);
  const [type, setType] = useState("");
  const [PopUp, setPopUp] = useState(false);
  // const [PopUpNewAcc, setPopUpNewAcc] = useState(false);
  // const [PopUpLog, setPopUpLog] = useState(false);
  // const [PopUpReset, setPopUpReset] = useState(false);

  const hidePopUp = () => {
    setPopUp(false);
    document.body.classList.remove("overflow-hidden");
  };
  // const hidePopUpNewAccount = () => {
  //   setPopUpNewAcc(false);
  //   document.body.classList.remove("overflow-hidden");
  // };
  // const hidePopUpLogin = () => {
  //   setPopUpLog(false);
  //   document.body.classList.remove("overflow-hidden");
  // };
  // const hidePopUpReset = () => {
  //   setPopUpReset(false)
  //   document.body.classList.remove("overflow-hidden");
  // }

  const closeMenuOnLinkClick = () => {
    setOpen(false);
    setFixMenu(false);
    setType(null);
  };

  const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !event.target.closest(".otherLanguages") &&
      !event.target.closest(".h-5")
    ) {
      closeMenuOnLinkClick();
    }
  };

  const ref = useOutsideClick(handleClickOutside);

  const handleClickMenuItem = (type) => {
    if (type === "login") {
      setType("login");
      // setPopUpLog(true);
    } else if (type === "inscription") {
      setType("inscription");
      // setPopUpNewAcc(true);
    }
  };

  // const onValidated = () => {
  //   setPopUpNewAcc(false);
  //   setPopUpLog(true);
  // };

  // const onConnectPress = () => {
  //   setPopUpNewAcc(false);
  //   setTimeout(() => {
  //     setPopUpLog(true);
  //   }, 500);
  // };

  // const onCreateAccount = () => {
  //   setPopUpLog(false);
  //   setTimeout(() => {
  //     setPopUpNewAcc(true);
  //   }, 500);
  // };

  // const onResetPwd = () => {
  //   setPopUpLog(false);
  //   setTimeout(() => {
  //     setPopUpReset(true);
  //   }, 500);
  // };

  return (
    <header
      ref={ref}
      className={`bg-darkBlue  relative py-5 text-white mb-5 ${
        fixMenu ? "fixedMenu" : ""
      }`}
    >
      {PopUp ? (
        <div className={"popUpContainer"}>
          <PopupCOntainer
            type={type}
            openModal={hidePopUp}
            isOpened={PopUp}
            inMobile={true}
          />
        </div>
      ) : null}

      <div className="width-padding  flex justify-between items-center">
        <Link
          to="/"
          onClick={() => {
            closeMenuOnLinkClick();
          }}
        >
          <img src={logo} alt="logo" width="200" height="48" />
        </Link>
        <BurgerMenu
          openMenu={open}
          handleClose={() => {
            setOpen(!open);
          }}
        />
        <MenuHeader
          handleClickMenuItem={handleClickMenuItem}
          desktopOrMobile="desktop"
        />
      </div>

      {/* Mobile Menu */}

      <div className="mobile">
        {PopUp ? (
          <PopupCOntainer
            type={type}
            openModal={hidePopUp}
            closeMenu={closeMenuOnLinkClick}
            isOpened={PopUp}
            inMobile={true}
          />
        ) : null}
        <AuthPopUp closeMenu={closeMenuOnLinkClick} openType={type} />
        {/* {PopUpNewAcc && (
          <PopupNewAccount
            type={"inscription"}
            openModal={hidePopUpNewAccount}
            closeMenu={closeMenuOnLinkClick}
            isOpened={PopUpNewAcc}
            inMobile={true}
            onValidated={onValidated}
            onConnect={onConnectPress}
          />
        )}
        {PopUpLog && (
          <PopupLogin
            type={"login"}
            openModal={hidePopUpLogin}
            closeMenu={closeMenuOnLinkClick}
            isOpened={PopUpLog}
            inMobile={true}
            onCreateAccount={onCreateAccount}
            onResetPwd={onResetPwd}
          />
        )}
        {PopUpReset && (
          <PopupResetPassword
            type={""}
            openModal={hidePopUpReset}
            closeMenu={closeMenuOnLinkClick}
            isOpened={PopUpReset}
            inMobile={true}
            // onCreateAccount={onCreateAccount}
            // onResetPwd={onResetPwd}
          />
        )} */}
        <MenuHeader
          handleClickMenuItem={handleClickMenuItem}
          desktopOrMobile="mobile"
          open={open}
          closeMenu={closeMenuOnLinkClick}
        />
      </div>
    </header>
  );
};

export default Header;
