import Button from "../../ui/Button";
import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import hours, { START_AND_END_HOURS_KEY } from "./hours";
//import { CalendarIcon } from "../../../data/Icons";
import { useDispatch, useSelector } from "react-redux";
import { getNotAvailableDates } from "../../../api/apiCalls";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import PopupCOntainer from "../PopupContainer";
import Loader from "../../ui/Loader";
import { useTranslation } from "react-i18next";
import {
  hoursNotAvailablePerDay,
  setReservationDetails,
} from "../../../redux/reservationSlice";
import {
  formateDate,
  getTodayDate,
  //getTomorrowDate,
  calculateTimeDifference,
  isValidDate,
  hasTimePassed,
  checkIfIntervalAvailable,
  getStartAndEndHours,
  frenchFormat,
  isTimeDifferance15MIn,
  add15Minutes,
} from "../../../utils/utils";

import Toast from "../../ui/Toast";

//styles
import fr from "date-fns/locale/fr";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import AuthPopUp from "../AuthPopUp";

const ReservationBox = () => {
  const { t } = useTranslation();
  //popUp states
  const [PopUp, setPopUp] = useState(false);
  // const [PopUpLog, setPopUpLog] = useState(false);
  // const [PopUpNew, setPopUpNew] = useState(false);
  const [type, setType] = useState("");
  const hidePopUp = () => {
    setPopUp(false);
    document.body.classList.remove("overflow-hidden");
  };

  // reservation states
  const swiperRef = useRef(null);
  const now = new Date();
  const currentDate = new Date(
    now.toLocaleString("en-US", { timeZone: "Europe/Paris" })
  );
  const [selectedDay, setSelectedDay] = useState(currentDate);
  //const [selectedDay, setSelectedDay] = useState(new Date());
  const [startHour, setStartHour] = useState("");
  const [endHour, setEndHour] = useState("");
  const hoursDay = hours;
  const [selectedButton, setSelectedButton] = useState(null);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [flag, setFlag] = useState(false);
  const availableReservations = useSelector(
    (state) => state.reservations.availableReservations
  );
  const hoursHide = useSelector((state) => state.reservations.hours);
  const reservationDetails = useSelector((state) => state.reservationDetails);

  const axiosPrivate = useAxiosPrivate();

  /// ALl CONDITIONS  TO LET USER VALID RESERVATION
  // IF StartHour < EndHour ===> isValidDate()
  // IF selected hours are not disabled (already selected) ===> !hoursHide[startHour.substring(0, 5)] &&
  // IF TIme has not passed ==> hasTimePassed()
  // if StartDate and endDate are not inside an interval of not available hours ==> checkIfIntervalAvailable
  // If startdate available but endDate are not (15min reservation free) ==> betweenInterval()

  // let user reserve if endDate Disabled but startDate not
  const betweenInterval =
    hoursHide &&
    (hoursHide[endHour.substring(0, 5)] ? true : false) &&
    !hoursHide[startHour.substring(0, 5)] &&
    isTimeDifferance15MIn(startHour, endHour);

  const allowUserToReserve =
    (isValidDate(startHour, endHour) &&
      !hoursHide[startHour.substring(0, 5)] &&
      !hoursHide[endHour.substring(0, 5)] &&
      !hasTimePassed(startHour) &&
      checkIfIntervalAvailable(startHour, endHour, availableReservations)) ||
    betweenInterval;

  useEffect(() => {
    document.body.classList.remove("overflow-hidden");
    getNotAvailableDates(dispatch);

    // change startEnd hours based on current Hour + notAvailableHours
    // set To currentHour

    if (selectedDay.getHours() === 23 && selectedDay.getMinutes() >= 45) {
      const tomorrow = new Date(selectedDay);
      tomorrow.setDate(tomorrow.getDate() + 1);
      setSelectedDay(tomorrow);
      const { start, end } = getStartAndEndHours();
      setStartHour(start + " " + formateDate(tomorrow));
      setEndHour(end + " " + formateDate(tomorrow));
    } else {
      // set start, end hours
      const { start, end } = getStartAndEndHours();
      setStartHour(start + " " + getTodayDate());
      setEndHour(end + " " + getTodayDate());
    }
  }, []);

  useEffect(() => {
    /*if(startHour.substring(0,5)==="23h45" && formateDate(selectedDay)===getTodayDate()){
      return;
}*/
    if (hoursHide && hoursHide[startHour.substring(0, 5)]) {
      const add15MinToStart =
        add15Minutes(startHour.substring(0, 5)) + " " + startHour.substring(6);
      const add15MinToEnd =
        add15Minutes(endHour.substring(0, 5)) + " " + startHour.substring(6);
      setStartHour(add15MinToStart);
      setEndHour(add15MinToEnd);
      for (let key in hoursHide) {
        if (hoursHide[key] !== startHour.substring(0, 5)) {
          break;
        }
      }
    }
    //availableReservations,startHour,endHour,hoursHide
  }, [startHour, endHour, availableReservations]);

  useEffect(() => {
    /// get not availableReservations per day
    if (
      availableReservations &&
      availableReservations[formateDate(selectedDay)]
    ) {
      dispatch(
        hoursNotAvailablePerDay(availableReservations[formateDate(selectedDay)])
      );
    } else {
      dispatch(hoursNotAvailablePerDay(""));
    }
    /// if current Date already reserved +15min
  }, [selectedDay]);

  const reserver = async (startDate, endDate, callback) => {
    // const controller = AbortController()
    try {
      const res = await axiosPrivate.post("reservation/reserve", {
        startDate: startDate,
        endDate: endDate,
      });

      if (res) {
        return callback(null, res);
      }
    } catch (error) {
      return callback(error, null);
    }
  };

  //Toast props  // responseType => "success" or "error"
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [responseType, setResponseType] = useState("success");
  const time = 5000;
  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  // Submit reservation
  const submitReservation = async () => {
    if (
      allowUserToReserve ||
      (hoursHide &&
        (hoursHide[endHour.substring(0, 5)] ? true : false) &&
        isTimeDifferance15MIn(startHour, endHour))
    ) {
      //reserver()

      //transform to backend data

      const start =
        startHour.substring(6) +
        " " +
        startHour.substring(0, 5).replace("h", ":");
      const end =
        endHour.substring(6) + " " + endHour.substring(0, 5).replace("h", ":");

      setLoader(true);
      await reserver(start, end, (err, res) => {
        if (!err) {
          if (res.data.success) {
            displayToast(t("reservationStep1.operation"), "success");
            const reservationInfo = {
              start: startHour,
              end: endHour,
              price: calculateTimeDifference(startHour, endHour, "price"),
              duration: calculateTimeDifference(startHour, endHour, "duration"),
              date: formateDate(selectedDay),
              id: res?.data?.id,
              checkout_url: res?.data?.checkout_url,
              checkoutId: res?.data?.checkoutId,
              clientSecret: res?.data?.clientSecret,
              currency: res?.data?.currency,
            };
            setTimeout(() => {
              localStorage.setItem(
                "reservationDetails",
                JSON.stringify(reservationInfo)
              );
              dispatch(setReservationDetails(reservationInfo));

              setLoader(false);
            }, 1500);
          } else if (
            !res?.data?.success ||
            res?.data?.message === "error.past.reservations" ||
            err.message === "error.past.reservations"
          ) {
            displayToast(t("other.creneauNonDisponible"), "error");
            setLoader(false);
          }
        } else {
          setLoader(false);
          if (
            !res?.data?.success ||
            res?.data?.message === "error.past.reservations" ||
            err.message === "error.past.reservations"
          ) {
            displayToast(t("other.creneauNonDisponible"), "error");
            return;
          }
          displayToast(err.message, "error");
        }
      });
    } else {
      displayToast(t("other.creneauNonDisponible"), "error");
    }
  };

  const memoriseUserSelection = () => {
    // const start =
    //     startHour.substring(6) +
    //     " " +
    //     startHour.substring(0, 5).replace("h", ":");
    //   const end =
    //     endHour.substring(6) + " " + endHour.substring(0, 5).replace("h", ":");
    if (startHour && endHour) {
      localStorage.setItem(START_AND_END_HOURS_KEY, startHour + "|" + endHour);
    }
  };

  useEffect(() => {
    // console.log('reservationDetails : ', reservationDetails)
  }, []);

  return (
    <div className="reservationBox">
      <h3 className="text-darkBlue font-semibold text-xs sm:text-base mb-5">
        {t("reservationStep1.informations")}
      </h3>
      <div className="sm:max-w-90">
        {/* DATE PICKER */}
        <div className="part-datePicker  overflow-hidden  md:mx-auto">
          <div className="flex justify-between  mb-4  flex-col sm:flex-row">
            <label className="bg-white mr-4 cursor-pointer my-2 flex items-center w-fit max-w-85  overflow-hidden font-semibold	 text-darkBlue  border-darkBlue  p-2 rounded">
              {/* <div className="mr-1 max-w-8 w-full xs:block"><CalendarIcon /></div>  */}
              <DatePicker
                dateFormat="dd/MM/yyyy"
                locale={fr}
                onFocus={(e) => e.target.blur()}
                inputProps={{ readOnly: true }}
                placeholderText="Sélectionnez une date"
                selected={selectedDay}
                onChangeRaw={(e) => {
                  e.preventDefault();
                }}
                className="date-picker-input cursor-pointer"
                onBeforeInput={(e) => {
                  e.preventDefault();
                }}
                onChange={(date) => {
                  setSelectedDay(date);
                  if (!flag) {
                    setStartHour(
                      startHour.substring(0, 5) + " " + formateDate(date)
                    );
                    setEndHour(
                      endHour.substring(0, 5) + " " + formateDate(date)
                    );
                  }
                }}
                minDate={new Date(Date.now())}
              />
            </label>

            {/* Start and endHour */}
            <div className="flex my-2   md:justify-end  items-center">
              {/* Start hour */}
              <div
                className={`${
                  selectedButton === 1 ? "bg-darkBlue  text-white" : ""
                } choosedHour border-darkBlue rounded	mx-1  cursor-pointer font-semibold text-darkBlue p-2  flex justify-between items-center`}
              >
                <span
                  className=" px-2 font-semibold	"
                  onClick={() => {
                    setFlag(true);
                    setSelectedButton(1);
                    const element = document.querySelector(
                      `.item-${startHour.substring(0, 5)}`
                    );

                    if (element) {
                      const slideTO =
                        element.parentNode.parentNode.getAttribute(
                          "data-swiper-slide-index"
                        );
                      swiperRef.current.swiper.slideTo(slideTO);
                    }
                  }}
                >
                  {startHour.length === 5
                    ? startHour
                    : startHour.substring(0, 5)}
                </span>
              </div>
              {/* A */}
              <span className="text-darkBlue mx-1">
                {" "}
                {t("reservationStep1.to")}
              </span>

              {/* ENd hour */}
              <div
                className={`${
                  selectedButton === 2 ? "bg-darkBlue text-white" : ""
                } choosedHour border-darkBlue rounded	mx-1  cursor-pointer font-semibold	text-darkBlue p-2 flex justify-between items-center`}
              >
                <span
                  className=" px-2 font-semibold"
                  onClick={() => {
                    setFlag(true);
                    setSelectedButton(2);

                    const element = document.querySelector(
                      `.item-${endHour.substring(0, 5)}`
                    );
                    if (element) {
                      const slideTO =
                        element.parentNode.parentNode.getAttribute(
                          "data-swiper-slide-index"
                        );
                      swiperRef.current.swiper.slideTo(slideTO);
                    }
                  }}
                >
                  {endHour.length === 5 ? endHour : endHour.substring(0, 5)}
                </span>
              </div>
            </div>
          </div>

          {/* Swiper */}
          {
            <div className="border-style2 my-5 mx-auto">
              <div
                className={`${
                  !selectedButton
                    ? "flex flex-col justify-center items-center flex-wrap"
                    : "hidden"
                }`}
              >
                <h3 className="text-darkBlue font-semibold text-xs sm:text-base">
                  {t("reservationStep1.prixParagraph")}
                </h3>
                <span className="text-darkBlue mt-2 text-xs sm:text-base font-bold">
                  {(allowUserToReserve ||
                    (hoursHide &&
                      (hoursHide[endHour.substring(0, 5)] ? true : false) &&
                      isTimeDifferance15MIn(startHour, endHour))) &&
                    calculateTimeDifference(startHour, endHour, "price")}
                  {(allowUserToReserve ||
                    (hoursHide &&
                      (hoursHide[endHour.substring(0, 5)] ? true : false) &&
                      isTimeDifferance15MIn(startHour, endHour))) &&
                    "€"}
                </span>
              </div>

              <div
                className={`swiper-div-container  ${
                  selectedButton ? "" : "hideIt"
                }`}
              >
                <Swiper
                  navigation={true}
                  spaceBetween={50}
                  loop={true}
                  slidesPerView={1}
                  //speed={100}
                  modules={[Navigation]}
                  //observer={"true"}
                  //observeslidechildren={"true"}
                  ref={swiperRef}
                >
                  {Array.from(hoursDay.entries()).map(([key, value]) => {
                    return (
                      <SwiperSlide key={`'grid-'+${key}`}>
                        <div className="grid grid-cols-2  sm:grid-cols-3 gap-3  mb-4 max-w-85 mx-auto">
                          {value.map((hour, index) => {
                            return (
                              <div
                                className={`${
                                  (!hoursHide || !hoursHide[hour]) &&
                                  !hasTimePassed(
                                    hour + " " + formateDate(selectedDay)
                                  )
                                    ? `duration-200	ease-out border-darkBlue rounded ${
                                        "item-" + hour
                                      }`
                                    : `${
                                        "item-" + hour
                                      } bg-whiteSmoke disabled font-semibold border rounded		border-midGreey cursor-default	text-midGreey`
                                }
                            ${
                              selectedButton === 1 &&
                              (startHour.length === 5
                                ? startHour
                                : startHour.substring(0, 5)) === hour
                                ? "bg-darkBlue text-white font-semibold	"
                                : selectedButton === 2 &&
                                  (endHour.length === 5
                                    ? endHour
                                    : endHour.substring(0, 5)) === hour
                                ? "bg-darkBlue text-white"
                                : "bg-white"
                            }
                            duration-100 font-semibold	text-darkBlue text-center p-2 rounded cursor-pointer`}
                                key={index}
                                onClick={() => {
                                  if (
                                    (!hoursHide || !hoursHide[hour]) &&
                                    !hasTimePassed(
                                      hour + " " + formateDate(selectedDay)
                                    )
                                  ) {
                                    setFlag(true);
                                    if (selectedButton === 1) {
                                      setStartHour(
                                        hour + " " + formateDate(selectedDay)
                                      );
                                      const hourStart = hour.substring(0, 2);
                                      const minutsStart = hour.substring(3);

                                      if (hour !== "23h45") {
                                        setEndHour(
                                          minutsStart === "45"
                                            ? (hourStart.substring(0, 1) ===
                                                "0" && hourStart !== "09"
                                                ? "0"
                                                : "") +
                                                (parseInt(hourStart) + 1) +
                                                "h00 " +
                                                formateDate(selectedDay)
                                            : hourStart +
                                                "h" +
                                                (parseInt(minutsStart) + 15) +
                                                " " +
                                                formateDate(selectedDay)
                                        );
                                        setSelectedButton(null);
                                        return;
                                      }
                                      const tomorrow = new Date(selectedDay);
                                      tomorrow.setDate(tomorrow.getDate() + 1);
                                      setSelectedDay(tomorrow);
                                      setEndHour(
                                        "00h00 " + formateDate(tomorrow)
                                      );

                                      setSelectedButton(null);
                                    } else {
                                      setEndHour(
                                        hour + " " + formateDate(selectedDay)
                                      );
                                      setSelectedButton(null);
                                    }
                                  }
                                }}
                              >
                                {hour}
                              </div>
                            );
                          })}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          }
        </div>
      </div>
      <div className="part-price border-y  border-solid border-darkGreey -mx-2 lg:-mx-8 px-4 py-4 mb-5">
        <div className="px-3">
          {/* Date de départ */}

          <div className="flex xxs:flex-row flex-col justify-between items-start mb-2">
            <h3 className="text-darkBlue font-semibold text-xs sm:text-base">
              {t("reservationStep1.depart")}
            </h3>
            <span className="text-darkBlue text-xs sm:text-base font-bold">
              {frenchFormat(startHour.substring(6, startHour.length)).replace(
                /-/g,
                "/"
              ) +
                t("reservationStep1.to2") +
                startHour.substring(0, 5)}
            </span>
          </div>

          {/* Date fin  */}

          <div className="flex xxs:flex-row flex-col justify-between items-start mb-2">
            <h3 className="text-darkBlue font-semibold text-xs sm:text-base">
              {t("reservationStep1.fin")}
            </h3>
            <span className="text-darkBlue text-xs sm:text-base font-bold">
              {frenchFormat(endHour.substring(6, startHour.length)).replace(
                /-/g,
                "/"
              ) +
                " " +
                t("reservationStep1.to2") +
                " " +
                endHour.substring(0, 5)}
            </span>
          </div>

          {/* Durée */}
          <div className="flex xxs:flex-row flex-col justify-between items-start flex-wrap mb-2">
            <h3 className="text-darkBlue font-semibold text-xs sm:text-base">
              {t("reservationStep1.duree")}
            </h3>
            <span className="text-darkBlue text-xs sm:text-base font-bold">
              {allowUserToReserve &&
                flag &&
                calculateTimeDifference(startHour, endHour, "duration")}
            </span>
          </div>

          <div className="flex xxs:flex-row flex-col justify-between items-start flex-wrap mb-2">
            <h3 className="text-darkBlue font-semibold text-xs sm:text-base">
              {t("reservationStep1.prixParagraph")}
            </h3>
            <span className="text-darkBlue text-xs sm:text-base font-bold">
              {allowUserToReserve &&
                flag &&
                calculateTimeDifference(startHour, endHour, "price")}
              {allowUserToReserve && flag && "€"}
            </span>
          </div>

          {!allowUserToReserve && flag && (
            <div className="flex justify-center items-center mt-2">
              <p className="text-red-400 font-bold text-sm text-center">
                {!isValidDate(startHour, endHour) &&
                  t("mesReservations.attentionHoraires")}
                {!checkIfIntervalAvailable(
                  startHour,
                  endHour,
                  availableReservations
                ) &&
                  "Votre réservation se trouve dans un intervalle de temps déjà réservé..."}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mb-4">
        <Button
          disabled={!flag || !allowUserToReserve}
          btnContent={t("reservationStep1.reserver")}
          customStyle={
            "btn-principal reserver-btn inline-block text-white rounded-lg px-10 sm:px-14"
          }
          type="submit"
          onClick={(e) => {
            if (localStorage.getItem("user")) {
              e.preventDefault();
              submitReservation();
            } else {
              memoriseUserSelection();
              setType("login");
              // setPopUp(true);
            }
          }}
        />

        {loader && (
          <div className="flex items-center justify-center my-3 -mb-5">
            <Loader />
          </div>
        )}

        <p className="mt-6 text-dimGreey text-sm	text-center font-light ">
          {t("reservationStep1.transactions")}
        </p>
      </div>
      {openToast && (
        <Toast
          responseType={responseType}
          message={message}
          open={openToast}
          close={handleCloseToast}
          duration={time}
        />
      )}
      {PopUp ? (
        <div>
          <PopupCOntainer
            type="inscription"
            openModal={hidePopUp}
            inReservation={{
              startDate:
                startHour.substring(6) +
                " " +
                startHour.substring(0, 5).replace("h", ":"),
              endDate:
                endHour.substring(6) +
                " " +
                endHour.substring(0, 5).replace("h", ":"),
            }}
            isOpened={PopUp}
          />
        </div>
      ) : null}
      <AuthPopUp
        closeMenu={() => {
          setType(null);
        }}
        openType={type}
        inReservation={{
          startDate:
            startHour.substring(6) +
            " " +
            startHour.substring(0, 5).replace("h", ":"),
          endDate:
            endHour.substring(6) +
            " " +
            endHour.substring(0, 5).replace("h", ":"),
        }}
      />
    </div>
  );
};

export default ReservationBox;
