import PaiementBox from "./paiement/PaiementBox";
import ReservationBox from "./reservation/ReservationBox";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "../../data/Icons";
import { useState, useEffect } from "react";
import BookingConfirmation from "./BookingConfirmation.jsx";
import { setReservationDetails } from "../../redux/reservationSlice";
import { finishedPayment } from "../../redux/paymentSlice";
import { useTranslation } from "react-i18next";
const StepsBooking = () => {
  const { t } = useTranslation();
  const reservationDetails = useSelector(
    (state) => state.reservations.reservationDetails
  );
  const [openPayOradresse, setOpen] = useState(false);
  const [confirmationBooking, setConfirmationBooking] = useState(false);
  const { finishPaymentProcess } = useSelector((state) => state.payment);
  const [isStepThree, setIsStepThree] = useState(false);
  const dispatch = useDispatch();
  const showConfirmationBox = () => {
    setConfirmationBooking(true);
  };

  useEffect(() => {
    const reservationInfo = JSON.parse(
      localStorage.getItem("reservationDetails")
    );
    const FinalStep = JSON.parse(localStorage.getItem("finishedPayment"));
    dispatch(setReservationDetails(reservationInfo));
    dispatch(finishedPayment(FinalStep));
  }, []);

  const titles = (option) => {
    if (!reservationDetails || !localStorage.getItem("user")) {
      return option === "title" ? t("reservationStep1.titre") : 1;
    } else if (
      reservationDetails &&
      localStorage.getItem("user") &&
      !finishPaymentProcess
    ) {
      return option === "title" ? t("reservationStep2.titre") : isStepThree ? 3 : 2;
    } else if (reservationDetails && localStorage.getItem("user") && isStepThree) {
      return option === "title" ? "Paiement" : 3;
    } else if (
      reservationDetails &&
      finishPaymentProcess &&
      localStorage.getItem("user")
    ) {
      return option === "title" ? "Confirmation" : 3;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="3-steps ll:-mt-16 lg:sticky lg:top-[20px] rounded-t-2xl rounded-b-2xl shadow-md ">
      <div className="title">
        {!openPayOradresse && (
          <h2 className="text-lightGreen font-bold flex flex-wrap justify-between bg-darkBlue  xxs:text-lg py-4 px-4 rounded-t-2xl">
            {titles("title")}
            <span>
              {t("reservationStep1.etape")} {titles("step")}/3
            </span>
          </h2>
        )}
        {reservationDetails && openPayOradresse && (
          <div className="bg-darkBlue py-4 px-4 rounded-t-2xl">
            <div className="cursor-pointer w-8 h-8" onClick={handleClose}>
              {" "}
              <CloseIcon />{" "}
            </div>
          </div>
        )}
      </div>
      <div className="content-box pt-4 mb-2 lg:px-8  px-2 border border-solid border-darkBlue rounded-b-2xl">
        {(!reservationDetails || !localStorage.getItem("user")) && (
          <ReservationBox />
        )}
        {reservationDetails &&
          localStorage.getItem("user") &&
          !finishPaymentProcess && (
            <PaiementBox
              showConfirmationBox={showConfirmationBox}
              open={openPayOradresse}
              handleOpen={handleOpen}
              handleClose={handleClose}
              onChangeToStepThree={() => {
                setIsStepThree(true)
              }}
            />
          )}
        {reservationDetails &&
          finishPaymentProcess &&
          localStorage.getItem("user") && <BookingConfirmation />}
      </div>
    </div>
  );
};

export default StepsBooking;
