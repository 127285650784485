const StatCard = ({ icon, title, description, customStyle }) => {
  return (
    <div
      className={`w-[210px] h-24 sm:w-[225px] p-2 lg:w-[250px] lg:h-[90px] lg:max-w-sm xl:w-[280px] xl:h-[120px] bg-white border rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 ${customStyle}`}
      style={{
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        opacity: 1,
        // top: "243px",
        // left: "416px",
      }}
    >
      <div className="flow-root">
        <div className="flex items-center space-x-2 sm:space-x-1 sm:m-2 xl:m-6">
          <div className="flex-shrink-0 ml-2 sm:mr-2">
            <img className="w-8 h-8 sm:w-10 sm:h-10 lg:w-12 lg:h-12 rounded-full" src={icon} alt="statics"/>
          </div>
          <div className="flex-1 min-w-0 ">
            <p className="text-lg font-bold text-lighBlack-500 truncate">
              {title}
            </p>
            <p className="text-base text-lightBlack-500 truncate ">
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatCard;
