
import {MapIcon, RemoveIcon} from "../../data/Icons"
import Button from "../ui/Button";
import {useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Modal from "../ui/Modal"
import AddFacture from "../../components/widgets/paiement/AddFacture" 
import { setAddresses } from "../../redux/billingAddressSlice";
import { useDispatch } from "react-redux";
import Toast from "../ui/Toast";
import { useTranslation } from "react-i18next";
const AdresseFacturation = ()=>{

    const {t}= useTranslation();
    const [openModal,setIsModalOpen] = useState(false);
    const [modify,setModify]= useState(false);
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const billingAddress = currentUser.billingAddress;
    const axiosPrivate = useAxiosPrivate();
    const dispatch= useDispatch();


    //Toast props  // responseType => "success" or "error"
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [responseType, setResponseType] = useState("success");
  const time = 5000;
  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
  };
 

    const handleOpenModal = (modifyOrNew) => {
        setModify(modifyOrNew)
        setIsModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setIsModalOpen(false);
      };


      // Add Adresse 
      const addAdresse = ()=>{
        handleOpenModal(false)
       
      }

      // Modify Adresse 
      const modifyAdresse =(adresse)=>{
        
        handleOpenModal({value:true,adresse:adresse})
      }

      // removeAdresse

      const removeAdresse = (id)=>{

        const deleteAdresse = async ()=>{
        
          const res = await axiosPrivate.delete(`user/billingAddress/${id}`);

          
          try{
            if(res){
             
              displayToast(t("adresseFacturation.messageSupprimer"),"success");
              const newbillingAddress = billingAddress.filter(item => item._id !== id);
              currentUser.billingAddress=newbillingAddress;
              const userToJson = JSON.stringify(currentUser);
              dispatch(setAddresses(newbillingAddress));
             
              localStorage.setItem("user",userToJson)
             
              
            }
          }
          catch(error){
            displayToast(t("moyensPaiement.porblemeSupprimer"),"error")
          }
        }
        //run function delete
        deleteAdresse()
      }



    return(
        
<div className="width-padding relative min-h-50vh pb-28">
<h2 className="text-darkBlue sm:text-center text-xl font-bold mb-2">{t("adresseFacturation.titre")}</h2>
{/* Box single adresse facture */}
{(billingAddress && billingAddress.length > 0) ? 
  billingAddress.map((adresse)=>{
    return (
      <div key={adresse._id} className="box md:max-w-80 semimd:max-w-45 mx-auto my-4 bg-white p-4 shadow-box border  border-whisperGreey">
        <div className="flex justify-between">
          <div className="flex flex-wrap">
           <MapIcon />
            <div className="content pl-3 mt-1 text-darkBlue font-medium lg:font-semibold ">
              <h3 className="text-darkBlue">{adresse.label}</h3>
              <div className="mt-2">
                <p>{adresse.address}</p>
                <p>{adresse.zipCode}{" "}{adresse.city}</p>
                <p>{adresse.country}</p>
              </div>
            </div>
          </div>
          {/* Supprimer */}
          <div className="cursor-pointer" onClick={()=>{
            removeAdresse(adresse._id)
          }}>
            <RemoveIcon/>
          </div>
        </div>
        <div className="mt-5 mx-auto max-w-80 lg:max-w-70">
          {/* Modifier */}
          <Button
            btnContent={t("adresseFacturation.modifier")}
            customStyle={"btn-principal w-full uppercase text-white rounded-xl"}
            onClick={()=>{
              modifyAdresse(adresse)
            }}
          />
        
        </div>
      </div>
    );
  }) :
  <div className="box md:max-w-80  semimd:max-w-45 mx-auto my-4 bg-white px-4 py-8 shadow-box border mt-10  border-whisperGreey">
    <h2 className="text-center font-semibold text-darkBlue">{t("adresseFacturation.ajouterUneAdresse")}</h2>
    <p className="mt-2 text-darkBlue text-center font-medium">({t("adresseFacturation.maxAdresseMessage")}) </p>
  </div>
}
{/* Ajouter */}
<div className=" mt-20 mb-4 width-padding  w-5/6 absolute -translate-x-1/2  left-1/2 bottom-1">
<Button btnContent={t("adresseFacturation.ajouterAdresse")} customStyle=
{"bg-lightGreen uppercase rounded-2xl text-center hover:bg-darkGreen w-full flex justify-center  md:max-w-80 semimd:max-w-45 mx-auto text-white"}
onClick={addAdresse} disabled={billingAddress?.length===3}
/>
</div>

<Modal isOpen={openModal} onClose={handleCloseModal}>
    <div>
    <AddFacture modify={modify} close={handleCloseModal}/>
    </div>
</Modal>
 {openToast && (
        <Toast
          responseType={responseType}
          message={message}
          open={openToast}
          close={handleCloseToast}
          duration={time}
        />
      )}
      
</div>
    )
}

export default AdresseFacturation;