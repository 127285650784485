import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MentionsLegals = () => {
  const {t}= useTranslation();
  return (
    <div
      className={`my-10 sm:max-w-80 width-padding mx-auto`}
      id="mentions-legals"
    >
      <h1 className="text-2xl mb-2 font-bold text-lightGreen">{t("mentionLegals.title")} :</h1>

      <article>
        <h2 className="mention-title">{t("mentionLegals.editorSite")} </h2>
        <p className="mention-paragraph">
          <Link className="mention-link" to="/">
            https://ecapsule.fr/
          </Link>{" "}
          {t("mentionLegals.paragraph1")}
        </p>
        <p className="mention-paragraph">
        {t("mentionLegals.paragraph2")}
          :{" "}
          <a className="mention-link" href="mailto:contact@minutup.com">
            contact@minutup.com.
          </a>
        </p>
        <p className="mention-paragraph">
        {t("mentionLegals.siteHeberge")}
          <br />
          {t("mentionLegals.siegeSocial")}
        </p>
        <p className="mention-paragraph">
        {t("mentionLegals.directeur")}
        </p>
      </article>
      <article>
        <h2 className="mention-title">{t("mentionLegals.propriete")}</h2>
        <p className="mention-paragraph">
        {t("mentionLegals.tousInformations")}{" "}
          <Link className="mention-link" to="/">
            https://eCAPSULE.fr
          </Link>{" "}
          {t("mentionLegals.textesPhotos")}
        </p>
        <p className="mention-paragraph">
        {t("mentionLegals.parapgraphConsequent")}
        </p>
        <p className="mention-paragraph">
        {t("mentionLegals.titreConception")}{" "}
          <Link className="mention-link" to="/">
            https://eCAPSULE.fr
          </Link>{" "}
          {t("mentionLegals.paragraphInfos")} <Link className="mention-link" to="/">
            https://eCAPSULE.fr
          </Link> {t("mentionLegals.proprieteMinutup")}
        </p>
      </article>
      <article>
        <h2 className="mention-title">{t("mentionLegals.title2")} </h2>
        <p className="mention-paragraph">
        {t("mentionLegals.liensParagraph1")}
        </p>
        <p className="mention-paragraph">
        {t("mentionLegals.liensParagraph2")}
        </p>
      </article>
      <article>
        <h2 className="mention-title">{t("mentionLegals.title3")} </h2>
        <p className="mention-paragraph">
        {t("mentionLegals.sasMinutup")}{" "}
          <Link className="mention-link" to="/">
            https://eCAPSULE.fr
          </Link>
          {t("mentionLegals.obligation")}{" "}
          <Link className="mention-link" to="/">
            https://eCAPSULE.fr
          </Link>{" "}
          {t("mentionLegals.pourraInterrompu")}
        </p>
        <p className="mention-paragraph">
        {t("mentionLegals.minutupSas")}
        </p>
      </article>
      <article>
        <h2 className="mention-title">{t("mentionLegals.title4")}</h2>
        <p className="mention-paragraph">
        {t("mentionLegals.sasParagraph")}{" "}
          <Link className="mention-link" to="/">
            https://eCAPSULE.fr
          </Link>
        {" "}{t("mentionLegals.loi")}
        </p>
      </article>
    </div>
  );
};

export default MentionsLegals;
