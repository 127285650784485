import Button from "../../ui/Button";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Toast from "../../ui/Toast";
import { useEffect } from "react";
import Loader from "../../ui/Loader";
import { useDispatch } from "react-redux";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";
import {
  addPaymentFailure,
  addPaymentStart,
  addPaymentSuccess,
} from "../../../redux/paymentSlice";
import { AmexIcon, CBIcon, Mastercard2Icon, VisaIcon } from "../../../data/Icons";

const AddMethod = ({ close }) => {
  const {t}=useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      currency: "EUR", // set default value for the "currency" field to "EUR"
    },
  });

  // to replace when the userSlice will be created
  const [dataFromApi, setDataFromApi] = useState(null);
  const [loader, setLoader] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newCard, setNewCard] = useState(null);
  const dispatch = useDispatch();

  // form Validation

  // inputName, condition to check
  const onChangeSetError = (inputName, condition) => {
    if (condition) {
      setError(inputName, true);
    } else {
      clearErrors(inputName);
    }
  };

  //Toast props  // responseType => "success" or "error"
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [responseType, setResponseType] = useState("success");
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };

  useEffect(() => {
    if (currentUser) {
      setValue("first", currentUser?.name?.first || "");
      setValue("last", currentUser?.name?.last || "");

      return;
    }
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        const res = await axiosPrivate.get("user");

        if (res) {
          setDataFromApi(res.data);
        }
      } catch (error) {
        return;
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, []);

  const handleAdding = (data) => {
    setNewCard(data);
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (dataFromApi) {
      setValue("first", dataFromApi.name?.first || "");
      setValue("last", dataFromApi.name?.last || "");
    }
  }, [dataFromApi, setValue]);

  useEffect(() => {
    const controller = new AbortController();

    if (!isSubmitting) {
      return () => {
        controller.abort();
      };
    }

    const addCard = async (data) => {
      setLoader(true);
      try {
        dispatch(addPaymentStart());
        const res = await axiosPrivate.post("user/card", data);
        if (res) {
          dispatch(addPaymentSuccess(res.data.data));
          const user = JSON.parse(localStorage.getItem("user"));
          if (user.cards) {
            user.cards.push(res.data.data);
          } else {
            user.cards = [res.data.data];
          }
          localStorage.setItem("user", JSON.stringify(user));

          displayToast(t("compte.informationsSaved"), "success");
          setTimeout(() => {
            setLoader(false);
            close();
          }, 1500);
        }
      } catch (error) {
        /* if (error.response.status !== 401 && error.response.status !== 400) {
          displayToast(
            error.response.data.message
              ? error.response.data.message
              : error.message,
            "error"
          );
          dispatch(addPaymentFailure());
        }
        */
        if (
          error?.response?.data?.message === "cards number exceeds the limit of 3"
        ) {
          displayToast(t("moyensPaiement.limitCardsError"), "error");
          dispatch(addPaymentFailure());
        } else if (error?.response?.data?.message === "cardExpirationDate is mandatory and must be a valid MM/YY form.") {
          displayToast(t("compte.dateExpNonValid"), "error");
          dispatch(addPaymentFailure());
        }
         else {
          displayToast(t(error.message), "error");
          dispatch(addPaymentFailure());
        }

        setLoader(false);
      }
    };

    addCard({
      currency: newCard?.currency, // ? newCard?.currency : "EUR" this field should be added to the form with a select type ("EUR", "GBP", "USD") - REQUIERED
      cardNumber: newCard?.cardNumber,
      cardExpirationDate: newCard?.cardExpire.replace("/", ""),
      cardCvx: newCard?.cvc,
      label: newCard?.libelle ? newCard?.libelle : "", // optional field
      firstName: dataFromApi?.name?.first
        ? dataFromApi?.name?.first
        : newCard?.first, //optional field
      lastName: dataFromApi?.name?.last
        ? dataFromApi?.name?.last
        : newCard?.last, // optional field
    });
  }, [isSubmitting, newCard]);

  return (
    <div>
      <h3 className="text-darkBlue text-lg font-semibold mb-4">
        {t("moyensPaiement.nouveauMoyen")}
      </h3>

      {/* Input user */}

      {/* Label */}
      <form onSubmit={handleSubmit(handleAdding)}>
        {/* Nom et prénom */}

        {/* Nom et prénom */}

        <div className="flex  justify-between   items-center pb-4  mt-4">
          <div className="w-full mr-4">
            <label className="text-darkBlue block mb-2 font-semibold">
              {t("compte.nom")}*
            </label>
            <input
              type="text"
              className="text-darkBlue capitalize w-full relative block font-medium border-style3 focus:outline-none focus:border-darkGreen"
              placeholder={t("compte.nom")}
              id="last"
              name="last"
              maxLength={20}
              {...register("last", {
                required: true,
                onChange: (e) => {
                  onChangeSetError("last", e.target.value.length < 1);
                },
              })}
            />
            {errors.last && (
              <span className="absolute text-red-400 text-xs">
              {t("moyensPaiement.champObligatoire")}
              </span>
            )}
          </div>
          <div className="w-full">
            <label className="text-darkBlue block mb-2 font-semibold">
            {t("compte.prenom")}*
            </label>
            <input
              type="text"
              placeholder={t("compte.prenom")}
              className="text-darkBlue relative block w-full font-medium border-style3 focus:outline-none capitalize focus:border-darkGreen"
              id="first"
              maxLength={20}
              {...register("first", {
                required: true,
                onChange: (e) => {
                  onChangeSetError("first", e.target.value.length < 1);
                },
              })}
              name="first"
            />
            {errors.first && (
              <span className="absolute text-red-400 text-xs">
              {t("moyensPaiement.champObligatoire")}
              </span>
            )}
          </div>
        </div>

        {/* Card infos */}
        <div className="mt-2">
          {/* Card number */}
          <div className="pb-4">
            <h3 className="text-darkBlue text-lg font-semibold mb-2">
            {t("moyensPaiement.numeroCard")}*
            </h3>
            <input
              type="number"
              placeholder="1234 1234 1234 1234"
              className="text-darkBlue block w-full placeholder-gray-500
          placeholder-opacity-75 font-medium border-style3 
       focus:outline-none focus:border-darkGreen
       "
              id="cardNumber"
              {...register("cardNumber", {
                required: true,
                pattern: /^\d{13,16}$/,
                onBlur: (e) => {
                  if (watch("cardNumber").length > 0) {
                    onChangeSetError(
                      "cardNumber",
                      !/^\d{13,16}$/.test(e.target.value)
                    );
                  }
                },
                onChange: (e) => {
                  let { value } = e.target;
                  if (value.length > 13 && value.length <= 16) {
                    clearErrors("cardNumber");
                  }
                },
              })}
              name="cardNumber"
            />

            {errors.cardNumber && (
              <span className="absolute text-red-400 text-xs">
                {t("compte.entreNumeroCarteValid")}
              </span>
            )}
          </div>
          <div className="flex mt-2  sm:w-4/5 pb-4  items-center ">
            <div className="mr-5">
              <input
                type="text"
                placeholder="MM/AA"
                className="text-darkBlue block w-full placeholder-gray-500
         font-medium border-style3  relative
       focus:outline-none focus:border-darkGreen
       "
                id="cardExpire"
                maxLength={5}
                {...register("cardExpire", {
                  required: true,
                  pattern: /^(0[1-9]|1[0-2])\/[0-9]{2}$/,
                  onChange: (e) => {
                    e.target.value = e.target.value.replace(/[^\d\/.-]/g, '')
                    let { value } = e.target;
                    if (value.length >= 2 && !value.includes("/") && e.nativeEvent.inputType !== 'deleteContentBackward') {
                      setValue("cardExpire", value + "/");
                    }
                    if (value.length === 5) {
                      onChangeSetError(
                        "cardExpire",
                        !/^(0[1-9]|1[0-2])\/[0-9]{2}$/.test(e.target.value)
                      );
                    }
                    if (value.length === 1 && value.includes("/")) {
                      setValue("cardExpire", "")
                      clearErrors("cardExpire")
                    }

                  },
                  onBlur: (e) => {
                    if (e.target.value.length > 0) {
                      onChangeSetError(
                        "cardExpire",
                        !/^(0[1-9]|1[0-2])\/[0-9]{2}$/.test(e.target.value)
                      );
                    } else {
                      clearErrors("cardExpire")
                    }
                  },
                })}
                name="cardExpire"
              />
              {errors.cardExpire && (
                <span className="absolute max-w-45 text-red-400 text-xs">
                  {t("moyensPaiement.enterMMAA")}
                </span>
              )}
            </div>
            <div>
              <input
                type="password"
                placeholder="CVC"
                className="text-darkBlue block w-full placeholder-gray-500
         font-medium border-style3 relative
       focus:outline-none focus:border-darkGreen"
                maxLength={3}
                id="cvc"
                {...register("cvc", {
                  required: true,
                  pattern: /^\d{3}$/,
                  onChange: (e) => {
                    e.target.value = e.target.value.replace(/[^\d.-]/g, '')
                    onChangeSetError("cvc", !/^\d{3}$/.test(e.target.value));
                  },
                })}
                name="cvc"
              />
              {errors.cvc && watch("cvc").length > 3 && (
                <span className="absolute  max-w-80  text-red-400 text-xs">
                  {t("moyensPaiement.enterCVC")}
                </span>
              )}
            </div>
          </div>
        </div>

        {/* radiobutton currency */}
        <div className="radioButtons mt-2 flex ">
          <div className="flex items-center mr-2">
            <input
              type="radio"
              value="EUR"
              {...register("currency", { required: true })}
              className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-lightGreen  focus:ring-2"
            />
            <label className="text-darkBlue">EUR</label>
          </div>

          <div className="flex  items-center">
            <input
              type="radio"
              value="GBP"
              {...register("currency", { required: true })}
              className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-lightGreen focus:ring-2"
            />
            <label className="text-darkBlue">GBP</label>
          </div>

          {/* <div className="ml-2 flex  items-center">
            <input
              type="radio"
              value="USD"
              {...register("currency", { required: true })}
              className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-lightGreen focus:ring-2"
            />
            <label className="text-darkBlue">USD</label>
          </div> */}
        </div>
        {/* Libelle */}
        <label className="text-darkBlue block mb-2 mt-4 font-semibold">
          {t("moyensPaiement.libelle")}
        </label>
        <input
          type="text"
          placeholder={t("moyensPaiement.facultatif")}
          name="libelle"
          maxLength={20}
          className="text-darkBlue block w-full placeholder-gray-500
          placeholder-opacity-75 font-medium border-style3 
       focus:outline-none focus:border-darkGreen
       "
          {...register("libelle")}
        />
        <div className="flex items-center justify-center my-4 flex-wrap">
          <Button
            disabled={!isValid || loader}
            btnContent={t("compte.save2")}
            customStyle={
              "btn-principal my-4 inline-block text-white rounded-lg px-10 sm:px-14"
            }
            type="submit"
          />
        </div>
        <div className="flex flex-row items-center justify-center my-4 flex-wrap gap-2 mt-2">
            <CBIcon />
            <Mastercard2Icon />
            <VisaIcon />
            <AmexIcon />
        </div>
        <div className="flex items-center justify-center my-4 flex-wrap">
          <img src="https://mangopay.com/terms/powered-by-mangopay.png" height="25%" width="25%" alt="mangopay" />
        </div>
      </form>
      {openToast && (
        <Toast
          responseType={responseType}
          message={message}
          open={openToast}
          close={handleCloseToast}
          duration={5000}
        />
      )}
      {loader && (
        <div className="flex items-center justify-center my-2">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default AddMethod;
